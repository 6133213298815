import { List, NumberField, TextField, useDataProvider } from 'react-admin';
import Datagrid from '../Commons/Datagrid';
import PostPagination from '../Commons/PostPagination';
import ListActions from '../Commons/ListActions';
import { useMediaQuery, createTheme } from '@material-ui/core';
import BreadCrumbCustom from '../Commons/BreadCrum';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import historyTracker from '../Commons/historyTracker';
import AuthNotification from '../Commons/AuthNotification';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import building from '../Commons/Icons/house-building.svg';
import credits from '../Commons/Icons/creditos.svg';
import house from '../Commons/Icons/home.svg';
import InternalMenu from '../Commons/InternalMenu';

const RequestList = (props) => {
  const [auth, setAuth] = useState(false);
  const [authCreate, setAuthCreate] = useState(false);
  const [openAuth, setOpenAuth] = useState(false);
  const dataProvider = useDataProvider();
  const history = useHistory();
  const useStyles = makeStyles(() => createStyles(StyleTheme));
  const classes = useStyles();
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    (async () => {
      try {
        const currentPath = history.location.pathname;
        historyTracker.addPath(currentPath, 'Tipo Inmuebles',2);

        const authdata = await dataProvider.auth('Auth', { id: 'RealStateType', data: { action: 'list,create' } });
        if (authdata.data.length > 0) {
          setAuth(authdata.data[0]);
          setOpenAuth(!authdata.data[0]);
          setAuthCreate(authdata.data[1]);
        } else {
          setAuth(false);
          setOpenAuth(true)
        }
      }
      catch {
      }
    })();
  }, [dataProvider, history.location.pathname]);

  const handleRedirectRealState = () => {
    history.push(`/realstate`);
  };

  const handleRedirectRealStateType = () => {
    history.push(`/realstatetype`);
  };

  const [openDownPayments, setOpeDownPayments] = useState(true);

  const handleClickOpenDownPayments = () => {
    setOpeDownPayments(!openDownPayments);
  };

  const menus = [{
    icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
    open: openDownPayments,
    openAction: handleClickOpenDownPayments,
    actions: [
      { icon: <img src={house} alt="logo" style={Style.MenuLogo} />, name: 'Inmuebles', onClick: handleRedirectRealState },
      { icon: <img src={building} alt="logo" style={Style.MenuLogo} />, name: 'Tipo Inmuebles', onClick: handleRedirectRealStateType },
    ]
  }];

  let pagination = <PostPagination menu={<InternalMenu menus={menus} />} />;

  return (
    <div class="container">
      <BreadCrumbCustom history={historyTracker.getHistory()} />
      {auth ? (
        <div class="row">
          <div class="col-12">
            {isSmall ? (
              <List empty={false} {...props} actions={<ListActions create={authCreate} />} bulkActionButtons={false} pagination={pagination}>
                <Datagrid {...props} rowClick="show">
                </Datagrid>
              </List>
            ) : (
              <List empty={false} perPage={10} {...props} actions={<ListActions download create={authCreate} />} bulkActionButtons={false} pagination={pagination}>
                <Datagrid {...props} rowClick="show" style={Style.DataGrid}>
                  <NumberField source="id" style={Style.fieldList} label="Código" sortable={false} cellClassName={classes.fields} />
                  <TextField source="description" style={Style.fieldList} label="Nombre" sortable={false} cellClassName={classes.fields} />
                </Datagrid>
              </List>
            )}
          </div>
        </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)
      }
    </div>
  )
};

export default RequestList;