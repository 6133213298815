import Button from '@material-ui/core/Button';
import { Style } from './Styles.jsx';

const BottomToolBar = (props) => {
  const { menu, buttons } = props;
  let col = buttons ? buttons.length * 2 : 0;
  col = 11 - col;
  return (
    <div class="row" style={{ marginTop: '1.2em' }}>
      {buttons && buttons.map((button, index) => {
        return button.auth && (
          <div class="col-2" key={index}>
            <Button
              style={index % 2 === 0 ? Style.SaveButton : Style.DeleteButton}
              onClick={button.action}
              disabled={props.disabled}>
              {button.name}
            </Button>
          </div>
        )
      })}
      <div class={"col-" + col}></div>
      {menu && (
        <div class="col-1">
          {menu}
        </div>
      )}
    </div>
  )
};

export default BottomToolBar;