import { useNotify, useDataProvider } from 'react-admin';
import { useEffect, useState } from "react";
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useMediaQuery } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import BreadCrumbCustom from '../Commons/BreadCrum';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import { Style } from '../Commons/Styles.jsx';
import BottomToolBar from '../Commons/BottomToolBar';
import AuthNotification from '../Commons/AuthNotification';
import building from '../Commons/Icons/house-building.svg';
import credits from '../Commons/Icons/creditos.svg';
import house from '../Commons/Icons/home.svg';
import InternalMenu from '../Commons/InternalMenu';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PostCreate = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [realState, setRealState] = useState('');
    const [price, setPrice] = useState('');
    const [cost, setCost] = useState('');
    const [amount, setAmount] = useState('');
    const [description, setDescription] = useState('');
    const [property, setProperty] = useState('');
    const [folio, setFolio] = useState('');
    const [book, setBook] = useState('');
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [type, setType] = useState(null);
    const [types, setTypes] = useState([]);
    const [openAuth, setOpenAuth] = useState(false);
    const [auth, setAuth] = useState(false);
    const dataProvider = useDataProvider();

    const notify = useNotify();
    const history = useHistory();

    const handleClickError = () => {
        setOpenerror(true);
    };

    useEffect(() => {
        (async () => {
            try {
                const authdata = await dataProvider.auth('Auth', { id: 'RealState', data: { action: 'create' } });
                if (authdata.data.length > 0) {
                    setAuth(authdata.data[0]);
                    setOpenAuth(!authdata.data[0]);
                } else {
                    setAuth(false);
                    setOpenAuth(true)
                    return;
                }

                const { data } = await dataProvider.getMany('realstatetype/', { ids: [] });
                var types = data.map(item => (
                    {
                        key: item.id,
                        value: item.description
                    }
                ));

                setTypes(types);
                setType(types[0].key);
            }
            catch {
            }
        })();
    }, [dataProvider]);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const PostRealState = async () => {
        let control = 0;

        if (!Validate(price, true, { money: true, required: true }))
            control++;


        if (!Validate(cost, true, { money: true, required: true }))
            control++;


        if (!Validate(amount, true, { price: true, required: true }))
            control++;


        if (!Validate(realState, true, { required: true }))
            control++;


        if (control > 0) {
            setMessage("No se pudo crear el Inmueble")
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            realState,
            price,
            cost,
            property,
            folio,
            book,
            description,
            type,
            amount
        }

        dataProvider.create('realstate', { data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                history.push('/realstate/' + response.data.id + '/show');
                notify('Inmueble creado correctamente')
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo actualizar el Inmueble. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const handleRedirectRealState = () => {
        history.push(`/realstate`);
    };

    const handleRedirectRealStateType = () => {
        history.push(`/realstatetype`);
    };

    const [openDownPayments, setOpeDownPayments] = useState(true);

    const handleClickOpenDownPayments = () => {
        setOpeDownPayments(!openDownPayments);
    };

    const menus = [{
        icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
        open: openDownPayments,
        openAction: handleClickOpenDownPayments,
        actions: [
            { icon: <img src={house} alt="logo" style={Style.MenuLogo} />, name: 'Inmuebles', onClick: handleRedirectRealState },
            { icon: <img src={building} alt="logo" style={Style.MenuLogo} />, name: 'Tipo Inmuebles', onClick: handleRedirectRealStateType },
        ]
    }];

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {auth ? (
                <div class="edit">
                    {!isSmall ? (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>) : (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>
                    )}
                    {!isSmall ? (
                        <div class="container" style={{ opacity: opacity }}>
                            <div class="row">
                                <InputFieldCustom value={realState} setValue={setRealState} col="col-6" width="95%" placeholder="Ingresa nombre del Inmueble" label="Nombre *" required type="text" />
                                <InputFieldCustom value={price} setValue={setPrice} col="col-6" width="95%" placeholder="Ingresa el precio del Inmueble" label="Precio (Q) *" required type="text" money />
                            </div>
                            <div class="row">
                                <InputFieldCustom value={cost} setValue={setCost} col="col-6" width="95%" placeholder="Ingresa el Costo" label="Costo(Q) *" required type="text" money />
                                <InputFieldCustom value={amount} setValue={setAmount} col="col-6" width="95%" placeholder="Ingresa el monto no facturable" label="Monto No Facturable (Q) *" required type="text" money />
                            </div>
                            <div class="row">
                                <InputFieldCustom value={property} setValue={setProperty} col="col-6" width="95%" placeholder="Ingresa Finca" label="Finca" type="text" />
                                <InputFieldCustom value={folio} setValue={setFolio} col="col-6" width="95%" placeholder="Ingresa Folio" label="Folio" required name="Folio" type="text" />
                            </div>
                            <div class="row">
                                <InputFieldCustom value={book} setValue={setBook} col="col-6" width="95%" placeholder="Ingresa Libro" label="Libro" type="text" />
                                <InputFieldCustom col="col-6" width='95%' setValue={setType} value={type} options={types} name="types" component="select" label="Tipo Inmueble" />
                            </div>
                            <div class="row">
                                <InputFieldCustom value={description} setValue={setDescription} col="col-6" width="95%" placeholder="Ingresa descripción del Inmueble" label="Descripción" name="Description" type="text" component="textarea" />
                            </div>
                            <BottomToolBar buttons={[{ auth: true, action: PostRealState, name: 'Crear' }]} disabled={disable} menu={<InternalMenu menus={menus} />} />
                        </div>
                    ) : (
                        <div class="container" style={{ opacity: opacity }}>

                        </div>)
                    }
                    <div class="row" style={Style.Alert}>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" style={Style.AlertMessage} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
        </div>
    )
}

export default PostCreate
