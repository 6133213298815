const StyleTheme = {
  fields: {
    textAlign: 'center'
  },
  customSelectInput: {
    minWidth: '90%',
    marginRight: '1em'
  },
  customSelectInputSide: {
    minWidth: '100%'
  },
  input: {
    "& div.filter-field": {
      width: '16.5%'
    },
  },
  inputSide: {
    "& div.filter-field": {
      width: '100%'
    },
  },
  root: {
    "& div.react-grid-Container": {
      color: "gray",
      textAlign: 'center',
      verticalAlign: 'middle',
      fontFamily: 'Gothic',
      paddingTop: '1em',
      fontSize: '0.85em',
      borderCollapse: 'collapse',
    },
    amount: {
      fontFamily: 'Gothic'
    }
  },
  dataTitle: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '0.85em'
  },
  dataInfo: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '1em',
    color: '#262150'
  }
}

const Style = {
  fieldList: {
    color: 'black',
    fontFamily: 'Gothic'
  },
  SelectCustom: {
    fontFamily: 'Gothic',
    border: '2px solid #EFEFEF',
    borderRadius: '7px',
    fontWeight: 'bold',
    fontSize: '0.85em',
    height: '2.7em',
    paddingInlineStart: '1em',
    marginTop: '0em'
  },
  MenuLogo: {
    maxWidth: '1.3em',
    maxHeight: '1.3em'
  },
  Flags: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '0.85em',
  },
  FilterLabel: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '0.85em',
    color: '#191268',
    width: '100%'
  },
  AllWidth: {
    width: '100%'
  },
  BreadCrum: {
    fontFamily: 'Gothic'
  },
  DataGrid: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingLeft: '1em',
    paddingright: '1em',
  },
  ReportTitle: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '1em',
    marginTop: '1em'
  },
  InputFieldLabel: {
    fontFamily: 'BoldGothic',
    fontWeight: 1000,
    color: '#191268',
    paddingLeft: '1em',
    fontSize: '0.85em',
    minWidth: "100%",
    borderColor: "white"
  },
  ToolBarButton: {
    background: 'white',
    width: '100%',
    color: '#191268',
    fontFamily: 'Gothic',
    textTransform: 'none',
    borderColor: '#191268',
    border: "2px solid #191268",
    borderRadius: "7px",
    fontSize: "0.75rem",
    marginRight: "0.5em",
    height: "2.5rem"
  },
  Search: {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    marginTop: `27px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: "absolute",
    left: "30%",
    marginLeft: "0",
    fontFamily: 'Gothic'
  },
  PostPaginatioinButton: {
    fontFamily: 'Gothic',
    color: "#191268"
  },
  Label: {
    fontFamily: 'Gothic',
    fontWeight: 'bold',
    fontSize: '1em',
    color: '#219731'
  },
  DataGridCustome: {
    width: '99.5%',
    marginLeft: '0.1em'
  },
  SaveButton: {
    background: '#191268',
    fontFamily: 'Gothic',
    textTransform: 'none',
    borderRadius: '7px',
    color: 'white',
    width: '100%'
  },
  DeleteButton: {
    background: 'white',
    color: '#191268',
    width: '100%',
    border: "2px solid #191268",
    fontFamily: 'Gothic',
    textTransform: 'none',
    borderRadius: '7px'
  },
  Alert: {
    textAlign: 'center',
    marginBottom: '1em',
    marginTop: '2em'
  },
  AlertMessage: {
    color: 'white',
    fontFamily: 'Gothic'
  },
  ExpandListRow: {
    marginTop: '1em'
  },
  ExpandListDate: {
    border: 'white',
    fontWeight: 'bold'
  },
  MenuItem: {
    color: '#191268',
    fontWeight: 'bold',
    fontSize: '1em',
    marginTop: '2em',
    fontFamily: 'Gothic'
  },
  UserMenuItem: {
    color: '#191268',
    fontWeight: 'bold',
    fontSize: '1em',
    fontFamily: 'Gothic'
  },
  Dialog: {
    background: '#191268',
    fontSize: "0.85em",
    color: 'white',
    fontFamily: 'Gothic',
    textTransform: 'none',
    borderRadius: '7px',
    width: '100%',
  }
}

export { StyleTheme, Style };