import { List, NumberField, TextField, TextInput, useDataProvider, SelectInput } from 'react-admin';
import Datagrid from '../Commons/Datagrid';
import PostPagination from '../Commons/PostPagination';
import ListActions from '../Commons/ListActions';
import { useMediaQuery, createTheme } from '@material-ui/core';
import BreadCrumbCustom from '../Commons/BreadCrum';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import historyTracker from '../Commons/historyTracker';
import AuthNotification from '../Commons/AuthNotification';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import FilterSidebar from '../Commons/FilterSidebar';
import credits from '../Commons/Icons/creditos.svg';
import settle from '../Commons/Icons/settle.svg';
import general from '../Commons/Icons/gears.svg';
import InternalMenu from '../Commons/InternalMenu';

const DownPaymentsList = (props) => {
  const useStyles = makeStyles(() => createStyles(StyleTheme));
  const classes = useStyles();
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const [advisors, setAdvisors] = useState([]);
  const [states, setStates] = useState([]);
  const [state, setState] = useState(null);
  const [statesSelect, setStatesSelect] = useState([]);
  const [globalStates, setGlobalStates] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [openAuth, setOpenAuth] = useState(false);
  const [auth, setAuth] = useState(false);
  const [authCreate, setAuthCreate] = useState(false);
  const dataProvider = useDataProvider();

  useEffect(() => {
    (async () => {
      try {
        const currentPath = history.location.pathname;
        historyTracker.addPath(currentPath, 'Enganches', 2);

        const authdata = await dataProvider.auth('Auth', { id: 'DownPayment', data: { action: 'list,create' } });
        if (authdata.data.length > 0) {
          setAuth(authdata.data[0]);
          setOpenAuth(!authdata.data[0]);
          setAuthCreate(authdata.data[1]);

          let data = await dataProvider.getMany('advisors/downpayments/general', { ids: [] });
          var advisors = data.data.map(item => (
            {
              id: item.id,
              name: item.name
            }
          ));

          setAdvisors(advisors);

          data = await dataProvider.getMany('agencies/downpayments/general', { ids: [] });
          var agencies = data.data.map(item => (
            {
              id: item.id,
              name: item.name
            }
          ));

          setAgencies(agencies);

          data = await dataProvider.getMany('state/downpayments/general', { ids: [] });
          var states = data.data.map(item => (
            {
              id: item.id,
              name: item.name,
              global: item.global
            }
          ));

          setStates(states);

          data = await dataProvider.getMany('state/global/downpayments/general', { ids: [] });
          var globalStates = data.data.map(item => (
            {
              id: item.id,
              name: item.name
            }
          ));

          setGlobalStates(globalStates);

        } else {
          setAuth(false);
          setOpenAuth(true)
        }
      }
      catch {
      }
    })();
  }, [dataProvider, history.location.pathname]);

  const handleStateGlobalChange = (event) => {
    var statesSelect = states.filter(item => item.global === event.target.value);
    setState('');
    setStatesSelect(statesSelect);
  }

  const handleStateChange = (event) => {
    setState(event.target.value);
  }

  const PostFilter = [
    <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Imueble"}</span>} source="realState" alwaysOn />,
    <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Cliente"}</span>} source="client" alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span >{"Estado Global"}</span>} onChange={handleStateGlobalChange} source="globalState" choices={globalStates} alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{"Estado"}</span>} value={state} onChange={handleStateChange} source="state" choices={statesSelect} alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{"Agencia"}</span>} source="agency" choices={agencies} alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{"Asesor"}</span>} source="advisor" choices={advisors} alwaysOn />
  ];

  const handleRedirectSettle = () => {
    history.push(`/downpayment/settlement`);
  };

  const handleRedirectGeneral = () => {
    history.push(`/downpayment/general`);
  };

  const [openDownPayments, setOpeDownPayments] = useState(true);

  const handleClickOpenDownPayments = () => {
    setOpeDownPayments(!openDownPayments);
  };

  const menus = [{
    icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
    open: openDownPayments,
    openAction: handleClickOpenDownPayments,
    actions: [
      { icon: <img src={general} alt="logo" style={Style.MenuLogo} />, name: 'General', onClick: handleRedirectGeneral },
      { icon: <img src={settle} alt="logo" style={Style.MenuLogo} />, name: 'Liquidaciones', onClick: handleRedirectSettle },
    ]
  }]

  let pagination = <PostPagination menu={<InternalMenu menus={menus} />} />;

  return (
    <div class="container">
      <BreadCrumbCustom history={historyTracker.getHistory()} />
      {auth ? (
        <div class="row">
          <div class="col-12">
            {isSmall ? (
              <List empty={false} {...props} filters={<PostFilter />} actions={<ListActions create={authCreate} />} bulkActionButtons={false} pagination={pagination}>
                <Datagrid {...props} rowClick="show">
                </Datagrid>
              </List>
            ) : (
              <List empty={false} perPage={10} {...props} aside={<FilterSidebar {...props} filters={PostFilter} />} actions={<ListActions download create={authCreate}  />} bulkActionButtons={false} pagination={pagination}>
                <Datagrid {...props} rowClick="show" >
                  <TextField source="realState" label="Inmueble" sortable={false} style={Style.fieldList} cellClassName={classes.fields} />
                  <TextField source="client" label="Cliente" sortable={false} style={Style.fieldList} cellClassName={classes.fields} styleheader={{ ...Style.DataGrid, width: "20em" }} />
                  <TextField source="downPaymentStartDateInfo" sortable={false} label="Fecha Inicio" style={Style.fieldList} cellClassName={classes.fields} />
                  <NumberField source="actualPrice" label="Precio" style={Style.fieldList} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <NumberField source="amount" label="Enganche" style={Style.fieldList} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <NumberField source="downPaymentBalance" label="Saldo" style={Style.fieldList} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <TextField source="stateId" label="Estado" sortable={false} style={Style.fieldList} cellClassName={classes.fields} />
                </Datagrid>
              </List>
            )}
          </div>
        </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
    </div>
  )
};

export default DownPaymentsList;