import States from './Components/Commons/States';
import SelectCustom from './Components/Commons/SelectCustom';
import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Style } from './Components/Commons/Styles.jsx';

const MyUserMenuView = (props) => {
    const { module } = props;
    const [organization, setOrganization] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const history = useHistory();

    const onChangeSelectOrganitation = (props) => {
        setOrganization(props.target.value);
        States.setOrganizationId(props.target.value);
        history.push(`/company/administration`);
    }

    useEffect(() => {
        (async () => {
            try {
                setOrganization(States.getOrganizationId());
                setOrganizations(States.getOrganizations());
            }
            catch {
            }
        })();
    }, []);

    return (
        <div class="row" style={{ width: '100%' }}>
            <div class={organizations.length > 1 ? "col-6" : 'col-8'}></div>
            <div class="col-2">
                <label style={Style.UserMenuItem}>
                    {module}
                </label>
            </div>
            <div class="col-2">
                <label style={Style.UserMenuItem}>
                    {States.getUser()}
                </label>
            </div>
            {organizations.length > 1 ? (
                <SelectCustom onChange={onChangeSelectOrganitation} select={organization} options={organizations} col="col-2" width='100%' type="text" />
            ) : (null)
            }
        </div>)
}

export default MyUserMenuView;
