import { Style } from '../Commons/Styles.jsx';

function SelectCustom(props) {
    const { onChange } = props;
    let width = props.width ? props.width : '100%';
    return (
        <div class={props.col ?? "col-6"}>
            {props.label ? (
                <div class="row" style={{ marginTop: '1.2em' }}>
                    <div class="col-12">
                        <label style={Style.InputFieldLabel}>{props.label}</label>
                    </div>
                </div>) : (null)
            }
            <div class="row" >
                <div class="col-12">
                    <select disabled={props.disabled} name={props.name} onChange={(e) => { onChange(e) }} style={{...Style.SelectCustom, width: width}}>
                        {
                            props.options.map((item) => (
                                <option value={item.key} selected={props.select === item.key}>{item.value}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
        </div>
    );
}

export default SelectCustom;