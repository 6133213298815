import { List, NumberField, TextField } from 'react-admin';
import Datagrid from '../Commons/Datagrid';
import PostPagination from '../Commons/PostPagination';
import ListActions from '../Commons/ListActions';
import { useMediaQuery, createTheme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import historyTracker from '../Commons/historyTracker';
import { useDataProvider } from "react-admin";
import AuthNotification from '../Commons/AuthNotification';
import { useLocation } from 'react-router-dom';
import { useListController } from 'react-admin';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';

const RequestList = (props) => {
  const useStyles = makeStyles(() => createStyles(StyleTheme));
  const classes = useStyles();
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [auth, setAuth] = useState(false);
  const [openerror, setOpenerror] = useState(false);
  const [elements, setelements] = useState(0);
  const [message, setMessage] = useState('')
  const { setModule } = props;
  const location = useLocation();
  const listContext = useListController(props);

  useEffect(() => {
    (async () => {
      try {
        if (listContext.loaded)
          setMessage('No Posees Empresas Asociadas');

        setelements(listContext.ids.length);
        setModule('Reportes');
        setAuth(true);
        setOpenerror(false)
        historyTracker.cleanHistory();
        const currentPath = history.location.pathname;
        historyTracker.addPath(currentPath, 'Compañías', 0);
        if (listContext.ids.length === 1) {
          history.push('/company/reports/' + listContext.ids[0] + '/show');
        }
      }
      catch (error) {
        console.log(error);
      }
    })();
  }, [dataProvider, setModule, location, listContext.ids, listContext.loaded, history]);

  return (
    <div class="container">
      {auth && elements > 1 ? (
        <div class="row">
          <div class="col-12">
            {isSmall ? (
              <List empty={false} {...props} actions={<ListActions />} bulkActionButtons={false} pagination={<PostPagination />}>
                <Datagrid {...props} rowClick="show">
                  <NumberField source="companyCode" style={Style.fieldList} label="Código" sortable={false} cellClassName={classes.fields} />
                  <TextField source="name" style={Style.fieldList} label="Orden" sortable={false} cellClassName={classes.fields} />
                </Datagrid>
              </List>
            ) : (
              <List empty={false} {...props} actions={<ListActions />} bulkActionButtons={false} pagination={<PostPagination />}>
                <Datagrid {...props} rowClick="show" style={Style.DataGrid}>
                  <NumberField source="companyCode" style={Style.fieldList} label="Código" sortable={false} cellClassName={classes.fields} />
                  <TextField source="name" style={Style.fieldList} label="Empresa" sortable={false} cellClassName={classes.fields} />
                </Datagrid>
              </List>
            )}
          </div>
        </div>) : auth && elements === 0 ? (
          <div class="row">
            <div class="col-2"></div>
            <div class="col-8 common" >
              <h2>{message}</h2>
            </div>
          </div>) :
        (<AuthNotification openerror={openerror} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)
      }
    </div>
  )
};

export default RequestList;