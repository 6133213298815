import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useState, useEffect } from "react";
import { Style } from '../Commons/Styles.jsx';


const SimpleDialog = (props) => {
    const { title, Onsave, body, maxWidth, action, close, setClose, disabled } = props;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                setOpen(!close);
            }
            catch (error) {
                console.log(error);
            }
        })();
    }, [close]);

    const OpenDialog = () => {
        setOpen(true);
        setClose(false);
    };

    const CloseDialog = () => {
        setOpen(false);
    };

    return (
        <div clas="container" style={{width:'76.25%'}}>
            <div class="row">
                <div class="col-12">
                    <Button variant="outlined" color="primary" style={{...Style.Dialog, opacity: disabled ? '0.5': '1'}} onClick={OpenDialog} disabled={disabled}>
                        {title}
                    </Button>
                </div>
                <Dialog open={open} onClose={CloseDialog} maxWidth={maxWidth}>
                    <DialogTitle><h3>{title}</h3></DialogTitle>
                    <DialogContent>
                        <div class="row">
                            <div class="col-12">
                                {body}
                            </div>
                        </div>
                    </DialogContent>
                    {action ?
                        (<DialogActions>
                            <Button style={Style.SaveButton} onClick={Onsave}>
                                Guardar
                            </Button>
                        </DialogActions>)
                        : null
                    }
                </Dialog>
            </div>
        </div>
    );
}

export default SimpleDialog;