import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState } from "react";
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import * as React from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import menu from '../Commons/Icons/apps.svg';
import { Style } from '../Commons/Styles.jsx';

const InternalMenu = (props) => {
    const { menus } = props;
    const [state, setState] = useState({
        bottom: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onKeyDown={toggleDrawer(anchor, false)}
        >
            {menus.map((menu) => (
                <List>
                    <ListItemButton onClick={menu.openAction}>
                        <ListItemIcon>
                            {menu.icon}
                        </ListItemIcon>
                        <ListItemText primary="Enganches" />
                        {menu.open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={menu.open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {menu.actions.map((action) => (
                                <ListItem key={action.name} disablePadding onClick={action.onClick}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {action.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={action.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </List>
            ))}
        </Box>
    );

    return (
        <div>
            <React.Fragment key={'bottom'}>
                <Button
                    onClick={toggleDrawer('bottom', true)}
                    style={{ outline: 'none' }}> {
                        <img src={menu}
                            alt="logo"
                            style={Style.MenuLogo} />
                    }
                </Button>

                <SwipeableDrawer
                    anchor={'bottom'}
                    open={state['bottom']}
                    onClose={toggleDrawer('bottom', false)}
                    onOpen={toggleDrawer('bottom', true)}
                >
                    {list('bottom')}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}

export default InternalMenu;