import { Style } from '../Commons/Styles.jsx';
import { useRef, useState, useEffect } from "react";
import Validate from '../Commons/Validate';


const InputFieldCustom = (props) => {
    const {
        type, placeholder, readOnly, width, value, setValue, required,
        money, component, options, date, onChange, search, error, setError
    } = props;
    const timer = useRef(null);
    const [color, setColor] = useState('#EFEFEF');

    useEffect(() => {
        (async () => {
            try {
                if (error)
                    setColor('red');
                else
                    setColor('#EFEFEF');
            }
            catch (e) {
                console.log(e);
            }
        })();
    }, [error]);

    const onChangeDefaultSelect = (props) => {
        let valueinfo = props.target.value;
        setValue(valueinfo);
        if (onChange) {
            onChange(props);
        }
    }

    const onChangeDefault = (props) => {
        let valueinfo = props.target.value;
        setValue(valueinfo);
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            if (money) {
                let decimals = (valueinfo.indexOf('.') !== -1) ? (valueinfo.length - valueinfo.indexOf('.') - 1) : 0;
                let valuemoney = valueinfo.replace(/[^0-9.-]/g, '');
                if (isNaN(valuemoney) || valuemoney === '') {
                    setValue('0.00');
                    return;
                }

                let money = decimals > 2 ? parseFloat(valuemoney) * (Math.pow(10, (decimals - 2))) : parseFloat(valuemoney);
                if (money > 9999999999999) {
                    let newprice = parseFloat(value.replace(/[^0-9.-]/g, ''));
                    setValue(newprice.toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                    return;
                }

                setValue(money.toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 }));

            }

            if (!Validate(props.target.value, { required: required, money: money, date: date })) {
                setColor('red')
            } else {
                setColor('#EFEFEF');
                if (setError)
                    setError(false);
            }

            if (onChange) {
                onChange(props);
            }

        }, 500);

    };

    function renderComponent(component) {
        switch (component) {
            case 'textarea':
                return (
                    <div class="row">
                        <div class="col-12">
                            <textarea className="InputField" type={type} onChange={(event) => onChangeDefault(event)} placeholder={placeholder} style={{ borderColor: color, width: width ? width : '80%', minHeight: '5em' }} readOnly={readOnly} value={value} />
                        </div>
                    </div>
                );
            case 'select':
                return (
                    <div class="row">
                        <div class="col-12">
                            <select disabled={readOnly} onChange={(e) => { onChangeDefaultSelect(e) }} style={{ ...Style.SelectCustom, width: width }}>
                                {
                                    options.map((item) => (
                                        <option value={item.key} selected={value === item.key}>{item.value}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                );
            case 'search':
                return (
                    <div class="row">
                        <div class="col-9">
                            <input className="InputField" type={type} placeholder={placeholder} style={{ borderColor: color, width: width ? width : '80%' }} readOnly={readOnly} value={value} />
                        </div>
                        <div class="col-3">
                            {search}
                        </div>
                    </div>
                );
            case 'input':
            default:
                return (
                    <div class="row">
                        <div class="col-12">
                            <input className="InputField" type={type} onChange={(event) => onChangeDefault(event)} placeholder={placeholder} style={{ borderColor: color, width: width ? width : '80%' }} readOnly={readOnly} value={value} />
                        </div>
                    </div>
                );
        }
    }


    return (
        <div class={props.col ? props.col : "col-6"}>
            <div class="row" style={{ paddingTop: '1.2em' }}>
                <div class="col-12">
                    <label style={Style.InputFieldLabel}>{props.label}</label>
                </div>
            </div>
            {renderComponent(component)}
        </div >
    );
};

export default InputFieldCustom;