import { useNotify, useDataProvider } from 'react-admin';
import { useEffect, useState } from "react";
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useMediaQuery } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import BreadCrumbCustom from '../Commons/BreadCrum';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@mui/material/Typography';
import AuthNotification from '../Commons/AuthNotification';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import BottomToolBar from '../Commons/BottomToolBar';
import building from '../Commons/Icons/house-building.svg';
import credits from '../Commons/Icons/creditos.svg';
import house from '../Commons/Icons/home.svg';
import InternalMenu from '../Commons/InternalMenu';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PostCreate = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [description, setDescription] = useState('');
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [id, setId] = useState(0);
    const [control, setControl] = useState(0);
    const [opensnack, setOpensnack] = useState(false);
    const [active, setActiveChecked] = useState(false);
    const [openAuth, setOpenAuth] = useState(false);
    const [auth, setAuth] = useState(false);
    const [authUpdate, setAuthUpdate] = useState(false);
    const [authDelete, setAuthDelete] = useState(false);

    const dataProvider = useDataProvider();
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const notify = useNotify();
    const history = useHistory();

    const handleClick = () => {
        setOpensnack(true);
    };

    const handleCloseSnack = () => {
        setOpensnack(false);
    };

    const handleClickError = () => {
        setOpenerror(true);
    };

    useEffect(() => {
        (async () => {
            try {
                const record = await dataProvider.getOne(props.resource, { id: props.id });
                const authdata = await dataProvider.auth('Auth', { id: 'RealStateType', data: { action: 'read,update,delete' } });
                if (authdata.data.length > 0) {
                    setAuth(authdata.data[0]);
                    setOpenAuth(!authdata.data[0]);
                    setAuthUpdate(authdata.data[1]);
                    setAuthDelete(authdata.data[2]);
                    if (authdata.data[0]) {
                        const data = record.data;
                        setDescription(data.description);
                        setId(data.id);
                        setControl(data.control.toString());
                        setActiveChecked(data.active);
                        historyTracker.addPath(history.location.pathname, data.description, 3);
                    }
                } else {
                    setAuth(false);
                    setOpenerror(true)
                }
            }
            catch (error) {
                console.log(error)
            }
        })();
    }, [dataProvider, props.id, props.resource, history]);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const Update = async () => {
        let flag = 0;

        if (!Validate(description, true, { required: true }))
            flag++;

        if (flag > 0) {
            setMessage("No se pudo actualizar el tipo de Inmueble")
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            description,
            control,
            active
        }

        dataProvider.update('realstatetype', { id: id, data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('Inmueble actualizado correctamente');
                handleClick();
                setControl(response.data.control);
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo actualizar el Inmueble. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const Delete = async () => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            control
        }

        dataProvider.delete('realstatetype', { id: id, previousData: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                notify('Tipo Inmueble eliminado correctamente')
                history.push('/realstatetype/');
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo eliminar el Tipo de Inmueble. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const handleChangeActive = () => {
        setActiveChecked(!active);
    };

    const handleRedirectRealState = () => {
        history.push(`/realstate`);
    };

    const handleRedirectRealStateType = () => {
        history.push(`/realstatetype`);
    };

    const [openDownPayments, setOpeDownPayments] = useState(true);

    const handleClickOpenDownPayments = () => {
        setOpeDownPayments(!openDownPayments);
    };

    const menus = [{
        icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
        open: openDownPayments,
        openAction: handleClickOpenDownPayments,
        actions: [
            { icon: <img src={house} alt="logo" style={Style.MenuLogo} />, name: 'Inmuebles', onClick: handleRedirectRealState },
            { icon: <img src={building} alt="logo" style={Style.MenuLogo} />, name: 'Tipo Inmuebles', onClick: handleRedirectRealStateType },
        ]
    }];

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {auth ? (
                <div class="edit">
                    {!isSmall ? (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>) : (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>
                    )}
                    {!isSmall ? (
                        <div class="container" style={{ opacity: opacity }}>
                            <div class="row">
                                <InputFieldCustom readOnly={!authUpdate} value={description} setValue={setDescription} col="col-6" width="95%" placeholder="Ingresa Tipo de Inmueble" label="Nombre" type="text" />
                                <div class="col-6">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={!authUpdate}
                                                checked={active}
                                                onChange={handleChangeActive}
                                                color="primary"
                                            />}
                                        label={
                                            <Typography style={Style.Flags}>Activo
                                            </Typography>
                                        }
                                    />
                                </div>
                            </div>
                            <BottomToolBar buttons={[{ auth: authUpdate, action: Update, name: 'Guardar' }, { auth: authDelete, action: Delete, name: 'Eliminar' }]} disabled={disable} menu={<InternalMenu menus={menus} />} />
                        </div>
                    ) : (
                        <div class="container" style={{ opacity: opacity }}>
                        </div>
                    )
                    }
                    <div class="row" style={Style.Alert}>
                        <div>
                            <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                <div>
                                    <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" style={Style.AlertMessage} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)
            }
        </div>
    )
}

export default PostCreate
