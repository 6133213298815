import { useNotify, useDataProvider } from 'react-admin';
import { useEffect, useState } from "react";
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useMediaQuery } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import BreadCrumbCustom from '../Commons/BreadCrum';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import AuthNotification from '../Commons/AuthNotification';
import States from '../Commons/States';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import BottomToolBar from '../Commons/BottomToolBar';
import credits from '../Commons/Icons/creditos.svg';
import settle from '../Commons/Icons/settle.svg';
import general from '../Commons/Icons/gears.svg';
import InternalMenu from '../Commons/InternalMenu';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateSettlementDownPayment = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [comment, setComment] = useState('');
    const [commentcolor, setCommentColor] = useState('#EFEFEF');
    const [commentflagtouched, setCommentFlagTouched] = useState(false);
    const [opensnack, setOpensnack] = useState(false);
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [state, setState] = useState(null);
    const [control, setControl] = useState(0);
    const [openAuth, setOpenAuth] = useState(false);
    const [auth, setAuth] = useState(true);
    const [authUpdate, setAuthUpdate] = useState(false);
    const [authDelete, setAuthDelete] = useState(false);
    const [id, setId] = useState('');
    const dataProvider = useDataProvider();
    const history = useHistory();
    const notify = useNotify();

    const handleClick = () => {
        setOpensnack(true);
    };

    const handleCloseSnack = () => {
        setOpensnack(false);
    };

    const handleClickError = () => {
        setOpenerror(true);
    };

    useEffect(() => {
        (async () => {
            try {
                const authdata = await dataProvider.auth('Auth', { id: 'SettlementDownPayment', data: { action: 'read,update,delete' } });
                if (authdata.data.length > 0) {
                    setAuth(authdata.data[0]);
                    setOpenAuth(!authdata.data[0]);
                    setAuthUpdate(authdata.data[1]);
                    setAuthDelete(authdata.data[2]);
                    if (authdata.data[0]) {
                        const { data } = await dataProvider.getOne('settlement/downpayment', { id: props.id });
                        data.amount = parseFloat(data.amount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 })
                        setState(data);
                        setComment(data.comment);
                        setControl(data.control.toString());
                        setId(data.correlative);
                    }
                } else {
                    setAuth(false);
                    setOpenerror(true);
                }
            }
            catch (error) {
                console.log(error)
            }
        })();
    }, [dataProvider, props]);

    const onChangeComment = (props) => {
        setComment(props.target.value);
        if (!Validate(props.target.value, commentflagtouched, { required: true })) {
            setCommentColor('red')
        } else {
            setCommentColor('#EFEFEF')
        }
    }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const Update = async () => {
        let flag = 0;

        if (!Validate(comment, true, { required: true })) {
            setCommentColor("red");
            flag++;
        }

        if (flag > 0) {
            setMessage("No se pudo actualizar la liquidación")
            handleClickError();
            setCommentFlagTouched(true);
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            comment,
            control
        }

        dataProvider.update('settlement/downpayment', { id: id, data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('Liquidación actualizada correctamente');
                setControl(response.data.control);
                handleClick();
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo actualizar la Liquidación. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const Delete = async () => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            control
        }

        dataProvider.delete('settlement/downpayment', { id: id, previousData: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                notify('Detalle de Liquidación eliminada correctamente')
                history.push('/downpayment/' + States.getDownPayment() + '/show');
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo eliminar el Detalle de Liquidación. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();

    const handleRedirectSettle = () => {
        history.push(`/downpayment/settlement`);
    };

    const handleRedirectGeneral = () => {
        history.push(`/downpayment/general`);
    };

    const [openDownPayments, setOpeDownPayments] = useState(true);

    const handleClickOpenDownPayments = () => {
        setOpeDownPayments(!openDownPayments);
    };

    const menus = [{
        icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
        open: openDownPayments,
        openAction: handleClickOpenDownPayments,
        actions: [
            { icon: <img src={general} alt="logo" style={Style.MenuLogo} />, name: 'General', onClick: handleRedirectGeneral },
            { icon: <img src={settle} alt="logo" style={Style.MenuLogo} />, name: 'Liquidaciones', onClick: handleRedirectSettle },
        ]
    }]

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {auth ? (
                <div class="edit">
                    {!isSmall ? (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>) : (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>
                    )}
                    {!isSmall ? (
                        <div class="container" style={{ opacity: opacity }}>
                            <div class="row">
                                <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={state?.settlementId} label="Enganche" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={state?.realState} label="Inmueble" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={state?.client} label="Cliente" readOnly={true} />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={state?.dateInfo} label="Fecha" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={state?.descriptionTransactionType} label="Tipo Transacción" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={state?.amount} label="Monto" readOnly={true} />
                            </div>
                            <div class="row">
                                <InputFieldCustom readOnly={!authUpdate} value={comment} bordercolor={commentcolor} onChange={onChangeComment} touched={commentflagtouched} col="col-4" width="100%" placeholder="Ingrese Comentario" label="Commentario *" required name="Comment" component="textarea" />
                            </div>
                            <BottomToolBar buttons={[{ auth: authUpdate, action: Update, name: 'Guardar' }, { auth: authDelete, action: Delete, name: 'Eliminar' }]} disabled={disable} menu={<InternalMenu menus={menus} />} />
                        </div>
                    ) : (
                        <div class="container" style={{ opacity: opacity }}>
                        </div>)
                    }
                    <div class="row" style={Style.Alert}>
                        <div>
                            <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                <div>
                                    <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" style={Style.AlertMessage} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
        </div>
    )
}

export default UpdateSettlementDownPayment