function Validate(value, props) {
    if (props.required) {
        if (value === undefined || value === null || value.length === 0 || value.trim().length === 0)
            return false
    }

    if(props.date){
        let re = /^(?:(?:[1-9]\d{3}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01]))|(?:(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)|(?:[1-9]\d{3}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|30)))$/
        return re.test(String(value));
    }

    if (props.email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
    }

    if (props.product) {
        if (value.length > 50) {
            return false;
        } else {
            return true
        }
    }

    if (props.number) {
        return !isNaN(value)
    }

    if (props.money) {
        let money = value.replace(/[^0-9.-]/g, '');
        let isValid = /^\d+(\.\d+)?$/.test(money);

        if (!isValid)
            return false

        return !isNaN(money)
    }

    if (props.phone) {
        let phone = /\d*/g;
        let array = [...value.matchAll(phone)];
        if (array.length > 2)
            return false;

        if (array[0][0] === "")
            return false;

        return true;
    }

    if (props.nit) {
        if (value.toLowerCase() === 'cf')
            return true

        if (value.toLowerCase() === 'consumidor final')
            return true

        if (value[0] === '0')
            return false;

        if (value.length > 9 || value.length < 4)
            return false;

        var nd, add = 0;

        if (nd === /^(\d+)?([\dk])$/i.exec(value)) {
            nd[2] = (nd[2].toLowerCase() === 'k') ? 10 : parseInt(nd[2]);
            for (var i = 0; i < nd[1].length; i++) {
                add += ((((i - nd[1].length) * -1) + 1) * nd[1][i]);
            }

            return ((11 - (add % 11)) % 11) === nd[2];
        } else {
            return false;
        }
    }

    return true;
};

export default Validate;