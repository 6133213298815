import { useNotify, useDataProvider } from 'react-admin';
import { useEffect, useState } from "react";
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useMediaQuery } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import BreadCrumbCustom from '../Commons/BreadCrum';
import historyTracker from '../Commons/historyTracker';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import AuthNotification from '../Commons/AuthNotification';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import BottomToolBar from '../Commons/BottomToolBar';
import building from '../Commons/Icons/house-building.svg';
import credits from '../Commons/Icons/creditos.svg';
import house from '../Commons/Icons/home.svg';
import InternalMenu from '../Commons/InternalMenu';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateRealState = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [realState, setRealState] = useState('');
    const [price, setPrice] = useState('');
    const [cost, setCost] = useState('');
    const [amount, setAmount] = useState('');
    const [description, setDescription] = useState('');
    const [property, setProperty] = useState('');
    const [folio, setFolio] = useState('');
    const [book, setBook] = useState('');
    const [opensnack, setOpensnack] = useState(false);
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [type, setType] = useState(null);
    const [state, setState] = useState(null);
    const [states, setStates] = useState([]);
    const [types, setTypes] = useState([]);
    const [IVAChecked, setIVAChecked] = useState(false);
    const [id, setId] = useState(0);
    const [control, setControl] = useState(0);
    const [openAuth, setOpenAuth] = useState(false);
    const [auth, setAuth] = useState(false);
    const [authUpdate, setAuthUpdate] = useState(false);
    const [authDelete, setAuthDelete] = useState(false);
    const dataProvider = useDataProvider();
    const history = useHistory();
    const notify = useNotify();

    const handleClick = () => {
        setOpensnack(true);
    };

    const handleCloseSnack = () => {
        setOpensnack(false);
    };

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleRedirectRealState = () => {
        history.push(`/realstate`);
    };

    const handleRedirectRealStateType = () => {
        history.push(`/realstatetype`);
    };

    const [openDownPayments, setOpeDownPayments] = useState(true);

    const handleClickOpenDownPayments = () => {
        setOpeDownPayments(!openDownPayments);
    };

    const menus = [{
        icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
        open: openDownPayments,
        openAction: handleClickOpenDownPayments,
        actions: [
            { icon: <img src={house} alt="logo" style={Style.MenuLogo} />, name: 'Inmuebles', onClick: handleRedirectRealState },
            { icon: <img src={building} alt="logo" style={Style.MenuLogo} />, name: 'Tipo Inmuebles', onClick: handleRedirectRealStateType },
        ]
    }];

    useEffect(() => {
        (async () => {
            try {
                const record = await dataProvider.getOne(props.resource, { id: props.id });
                const authdata = await dataProvider.auth('Auth', { id: 'RealState', data: { action: 'read,update,delete' } });
                if (authdata.data.length > 0) {
                    setAuth(authdata.data[0]);
                    setOpenAuth(!authdata.data[0]);
                    setAuthUpdate(authdata.data[1]);
                    setAuthDelete(authdata.data[2]);
                    const data = record.data;
                    if (authdata.data[0]) {
                        const datatypes = await dataProvider.getMany('realstatetype/', { ids: [] });
                        var types = datatypes.data.map(item => (
                            {
                                key: item.id,
                                value: item.description
                            }
                        ));

                        historyTracker.addPath(history.location.pathname, data.realState, 3);

                        setTypes(types);
                        setRealState(data.realState);
                        setPrice(parseFloat(data.price).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }));
                        setCost(parseFloat(data.cost).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }));
                        setType(data.type.toString());
                        setAmount(parseFloat(data.amount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }))
                        setProperty(data.property);
                        setFolio(data.folio);
                        setBook(data.book);
                        setDescription(data.description);
                        setIVAChecked(data.exonerationIVA);
                        setStates([{ key: 'A', value: 'Activo' }, { key: 'I', value: 'Inactivo' }]);
                        setState(data.state);
                        setId(data.id);
                        setControl(data.control.toString());
                    }
                } else {
                    setAuth(false);
                    setOpenerror(true)
                }
            }
            catch (error) {
                console.log(error)
            }
        })();
    }, [dataProvider, history, props.id, props.resource]);

    const handleChangeIVA = () => {
        setIVAChecked(!IVAChecked);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const Update = async () => {
        let flag = 0;

        if (!Validate(price, { money: true, required: true }))
            flag++;


        if (!Validate(cost, { money: true, required: true }))
            flag++;


        if (!Validate(amount, { money: true, required: true }))
            flag++;


        if (!Validate(realState, { required: true }))
            flag++;


        if (flag > 0) {
            setMessage("No se pudo actualizar el Inmueble")
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            realState,
            price: price.toString().replace(/[^0-9.-]/g, ''),
            cost: cost.toString().replace(/[^0-9.-]/g, ''),
            property,
            folio,
            book,
            description,
            type,
            amount: amount.toString().replace(/[^0-9.-]/g, ''),
            state,
            exonerationIVA: IVAChecked,
            control
        }

        dataProvider.update('realstate', { id: id, data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('Inmueble actualizado correctamente');
                handleClick();
                setControl(response.data.control);
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo actualizar el Inmueble. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const Delete = async () => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            control
        }

        dataProvider.delete('realstate', { id: id, previousData: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                notify('Inmueble eliminado correctamente')
                history.push('/realstate');
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo eliminar el Inmueble. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {auth ? (
                <div class="edit">
                    {!isSmall ? (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>) : (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>
                    )}
                    {!isSmall ? (
                        <div class="container" style={{ opacity: opacity }}>
                            <div class='row'>
                                <div class="col-3">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={IVAChecked}
                                                onChange={handleChangeIVA}
                                                color="primary"
                                            />}
                                        label={
                                            <Typography style={Style.Flags}>Exoneración IVA
                                            </Typography>
                                        }
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <InputFieldCustom readOnly={!authUpdate} value={realState} setValue={setRealState} col="col-6" width="95%" placeholder="Ingresa nombre del Inmueble" label="Nombre *" required type="text" />
                                <InputFieldCustom readOnly={!authUpdate} value={price} setValue={setPrice} col="col-6" width="95%" placeholder="Ingresa el precio del Inmueble" label="Precio (GTQ) *" required type="text" money />
                            </div>
                            <div class="row">
                                <InputFieldCustom readOnly={!authUpdate} value={cost} setValue={setCost} col="col-6" width="95%" placeholder="Ingresa el Costo" label="Costo(GTQ) *" required type="text" money />
                                <InputFieldCustom readOnly={!authUpdate} value={amount} setValue={setAmount} col="col-6" width="95%" placeholder="Ingresa el monto no facturable" label="Monto No Facturable (GTQ) *" required type="text" money />
                            </div>
                            <div class="row">
                                <InputFieldCustom readOnly={!authUpdate} value={property} setValue={setProperty} col="col-6" width="95%" placeholder="Ingresa Finca" label="Finca" name="Property" type="text" />
                                <InputFieldCustom readOnly={!authUpdate} value={folio} setValue={setFolio} col="col-6" width="95%" placeholder="Ingresa Folio" label="Folio" required name="Folio" type="text" />
                            </div>
                            <div class="row">
                                <InputFieldCustom readOnly={!authUpdate} value={book} setValue={setBook} col="col-6" width="95%" placeholder="Ingresa Libro" label="Libro" name="Book" type="text" />
                                <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setState} value={state} options={states} component="select" label="Estado" />
                            </div>
                            <div class="row">
                                <InputFieldCustom readOnly={!authUpdate} value={description} setValue={setDescription} col="col-6" width="95%" placeholder="Ingresa descripción del Inmueble" label="Descripción" name="Description" type="text" component="textarea" />
                                <InputFieldCustom disabled={!authUpdate} col="col-6" width='95%' setValue={setType} value={type} options={types} component="select" label="Tipo Inmueble" />
                            </div>
                            <BottomToolBar buttons={[{ auth: authUpdate, action: Update, name: 'Guardar' }, { auth: authDelete, action: Delete, name: 'Eliminar' }]} disabled={disable} menu={<InternalMenu menus={menus} />} />
                        </div>
                    ) : (
                        <div class="container" style={{ opacity: opacity }}>

                        </div>
                    )
                    }
                    <div class="row" style={Style.Alert}>
                        <div>
                            <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                <div>
                                    <Alert onClose={handleCloseSnack} severity="success" className={classes.myAlert} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" style={Style.AlertMessage} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)
            }
        </div >
    )
}

export default UpdateRealState