import { List, NumberField, TextField, TextInput, useDataProvider } from 'react-admin';
import Datagrid from '../Commons/Datagrid';
import PostPagination from '../Commons/PostPagination';
import ListActions from '../Commons/ListActions';
import { useMediaQuery, createTheme } from '@material-ui/core';
import BreadCrumbCustom from '../Commons/BreadCrum';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import historyTracker from '../Commons/historyTracker';
import AuthNotification from '../Commons/AuthNotification';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import FilterSidebar from '../Commons/FilterSidebar';
import credits from '../Commons/Icons/creditos.svg';
import settle from '../Commons/Icons/settle.svg';
import general from '../Commons/Icons/gears.svg';
import InternalMenu from '../Commons/InternalMenu';

const DownPaymentsList = (props) => {
  const useStyles = makeStyles(() => createStyles(StyleTheme));
  const classes = useStyles();
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const [openAuth, setOpenAuth] = useState(false);
  const [auth, setAuth] = useState(false);
  const dataProvider = useDataProvider();

  useEffect(() => {
    (async () => {
      try {
        const currentPath = history.location.pathname;
        historyTracker.addPath(currentPath, 'Enganches',2);

        const authdata = await dataProvider.auth('Auth', { id: 'DownPaymentSettlement', data: { action: 'list' } });
        if (authdata.data.length > 0) {
          setAuth(authdata.data[0]);
          setOpenAuth(!authdata.data[0]);
        } else {
          setAuth(false);
          setOpenAuth(true)
        }
      }
      catch {
      }
    })();
  }, [dataProvider, history.location.pathname]);

  const PostFilter = [
    <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Imueble"}</span>} source="realState" alwaysOn />
  ];

  const handleRedirectSettle = () => {
    history.push(`/downpayment/settlement`);
  };

  const handleRedirectGeneral = () => {
    history.push(`/downpayment/general`);
  };

  const [openDownPayments, setOpeDownPayments] = useState(true);

  const handleClickOpenDownPayments = () => {
    setOpeDownPayments(!openDownPayments);
  };

  const menus = [{
    icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
    open: openDownPayments,
    openAction: handleClickOpenDownPayments,
    actions: [
      { icon: <img src={general} alt="logo" style={Style.MenuLogo} />, name: 'General', onClick: handleRedirectGeneral },
      { icon: <img src={settle} alt="logo" style={Style.MenuLogo} />, name: 'Liquidaciones', onClick: handleRedirectSettle },
    ]
  }]

  let pagination = <PostPagination menu={<InternalMenu menus={menus} />} />;


  return (
    <div class="container">
      <BreadCrumbCustom history={historyTracker.getHistory()} />
      {auth ? (
        <div class="row">
          <div class="col-12">
            {isSmall ? (
              <List empty={false} {...props} actions={<ListActions />} bulkActionButtons={false} pagination={pagination}>
                <Datagrid {...props} rowClick="show">
                </Datagrid>
              </List>
            ) : (
              <List empty={false} perPage={10} {...props} aside={<FilterSidebar {...props} filters={PostFilter} />} actions={<ListActions download />} bulkActionButtons={false} pagination={pagination}>
                <Datagrid {...props} rowClick="show" style={Style.DataGrid}>
                  <TextField source="realState" style={Style.fieldList} label="Inmueble" sortable={false} cellClassName={classes.fields} />
                  <TextField source="client" style={Style.fieldList} label="Cliente" sortable={false} cellClassName={classes.fields} />
                  <NumberField source="priceRealState" style={Style.fieldList} label="Precio Inmueble" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <NumberField source="amountBankSettlement" style={Style.fieldList} label="Liquidación Bancaria" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <NumberField source="balanceSettled" style={Style.fieldList} label="Saldo por Liquidar" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <NumberField source="deductions" style={Style.fieldList} label="Deducciones" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <NumberField source="disbursementBank" style={Style.fieldList} label="Desemboloso" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <NumberField source="remainingBalance" style={Style.fieldList} label="Remanente" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                </Datagrid>
              </List>
            )}
          </div>
        </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
    </div>
  )
};

export default DownPaymentsList;