import { useEffect, useState } from "react";
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useMediaQuery } from '@material-ui/core';
import { useDataProvider, TextField, TextInput, SelectInput, NumberField, List, useNotify } from "react-admin";
import BreadCrumbCustom from '../Commons/BreadCrum';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import AuthNotification from '../Commons/AuthNotification';
import ReactDataGrid from "react-data-grid";
import States from '../Commons/States';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import FilterSidebar from '../Commons/FilterSidebar';
import ListActions from '../Commons/ListActions';
import PostPagination from '../Commons/PostPagination';
import Datagrid from '../Commons/Datagrid';
import SimpleDialog from '../Commons/Dialog.jsx';
import credits from '../Commons/Icons/creditos.svg';
import settle from '../Commons/Icons/settle.svg';
import general from '../Commons/Icons/gears.svg';
import InternalMenu from '../Commons/InternalMenu';
import BottomToolBar from '../Commons/BottomToolBar';
import Validate from '../Commons/Validate';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateDownPayment = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [globalState, setGlobalState] = useState('PRE');
    const [correlative, setCorrelative] = useState(0);
    const [openAuth, setOpenAuth] = useState(false);
    const [auth, setAuth] = useState(false);
    const [authUpdate, setAuthUpdate] = useState(false);
    const [authDelete, setAuthDelete] = useState(false);
    const [payments, setPayments] = useState([]);
    const [paymentPlan, setPaymentPlan] = useState([]);
    const [columns, setColumns] = useState([]);
    const [columnsPlan, setColumnsPlan] = useState([]);
    const dataProvider = useDataProvider();
    const history = useHistory();
    const [opensnack, setOpensnack] = useState(false);
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [agency, setAgency] = useState(null);
    const [agencies, setAgencies] = useState([]);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState(null);
    const [advisors, setAdvisors] = useState([]);
    const [advisor, setAdvisor] = useState(null);
    const [collectors, setCollectors] = useState([]);
    const [collector, setCollector] = useState(null);
    const [campaigns, setCampaigns] = useState([]);
    const [campaign, setCampaign] = useState(null);
    const [channels, setChannels] = useState([]);
    const [channel, setChannel] = useState(null);
    const [promotions, setPromotions] = useState([]);
    const [promotion, setPromotion] = useState(null);
    const [realstate, setRealState] = useState(null);
    const [realstateId, setRealStateId] = useState(null);
    const [price, setPrice] = useState(0.0);
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState(null);
    const [deadlines, setDeadlines] = useState([]);
    const [deadline, setDeadline] = useState(null);
    const [payment, setPayment] = useState(null);
    const [closeDialogRealState, setCloseDialogRealState] = useState(true);
    const [closeDialog, setCloseDialog] = useState(true);
    const [customer, setCustomer] = useState(null);
    const [customerInvoice, setCustomerInvoice] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [amount, setAmount] = useState("0.00");
    const [reserve, setReserve] = useState("0.00");
    const [commission, setCommission] = useState("0.00");
    const [installments, setInstallments] = useState(0);
    const [date, setDate] = useState(null);
    const [firstPayment, setFirstPayment] = useState(null);
    const [types, setTypes] = useState([]);
    const [disabledRealState, setDisabledRealState] = useState(true);
    const [error, setError] = useState(false);
    const [errorDate, setErrorDate] = useState(false);
    const [errorFirstPayment, setErrorFirstPayment] = useState(false);
    const [errorRealState, setErrorRealState] = useState(false);
    const [control, setControl] = useState(0);
    const [values, setValues] = useState({});
    const notify = useNotify();

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleCloseSnack = () => {
        setOpensnack(false);
    };

    const handleClick = () => {
        setOpensnack(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    useEffect(() => {
        (async () => {
            try {
                setOpacity(0.3);
                setDisable(true);
                setDisplayLoading('block');

                const authdata = await dataProvider.auth('Auth', { id: 'DownPayment', data: { action: 'read,update,delete' } });
                if (authdata.data.length > 0) {
                    setAuth(authdata.data[0]);
                    setOpenAuth(!authdata.data[0]);
                    setAuthUpdate(authdata.data[1]);
                    setAuthDelete(authdata.data[2]);

                    let data = await dataProvider.getOne(props.resource, { id: props.id });
                    data = data.data;

                    setGlobalState(data.globalState);
                    setDate(data.downPaymentStartDateInfo);
                    setFirstPayment(data.dateFirstPaymentInfo);
                    setCustomer(data.client);
                    setCustomerId(data.clientId);
                    setRealState(data.realState);
                    setRealStateId(data.realStateId);
                    setPrice(parseFloat(data.initialPrice).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }));
                    setInstallments(data.installment);
                    setAmount(parseFloat(data.amount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }));
                    setReserve(parseFloat(data.reserveAmount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }));
                    setCommission(parseFloat(data.commissionPaid).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }));
                    setCorrelative(data.correlative);
                    setControl(data.control);


                    if (data.globalState === 'PRE') {
                        let dataagencies = await dataProvider.getList('agencies/downpayments/general', { filter: {}, pagination: {}, sort: {} });
                        dataagencies = dataagencies.data;

                        var agencies = dataagencies
                            .filter(item => item.active === true)
                            .map(item => (
                                {
                                    key: item.id,
                                    value: item.name
                                }
                            ));

                        setAgencies(agencies);

                        if (agencies.length > 0) {
                            setAgency(data.agencyId);
                            const [productsData, advisorsData, collectorsData] = await Promise.all([
                                dataProvider.getList('products/downpayments/general/active', { filter: { agency: agencies[0].key }, pagination: {}, sort: {} }),
                                dataProvider.getList('advisors/downpayments/general/assigned', { filter: { agency: agencies[0].key }, pagination: {}, sort: {} }),
                                dataProvider.getList('collectors/downpayments/general/active', { filter: { agency: agencies[0].key }, pagination: {}, sort: {} })
                            ]);

                            var products = productsData.data.map(item => ({ key: item.id, value: item.name }));
                            setProducts(products);
                            if (products.length > 0) {
                                setProduct(data.productId);
                                setDisabledRealState(false);
                                States.setProductDownPayment(data.productId);
                            }

                            var advisors = advisorsData.data.map(item => ({ key: item.id, value: item.name }));
                            setAdvisors(advisors);
                            if (advisors.length > 0)
                                setAdvisor(data.advisorId);

                            var collectors = collectorsData.data.map(item => ({ key: item.id, value: item.name }));
                            setCollectors(collectors);
                            if (collectors.length > 0)
                                setCollector(data.collectorId);
                        }

                        const [campaignsData, channelsData, promotionsData, currenciesData, deadlinesData, paymentsData, typesData] = await Promise.all([
                            dataProvider.getList('campaign/downpayments/general/active', { filter: {}, pagination: {}, sort: {} }),
                            dataProvider.getList('channel/downpayments/general/active', { filter: {}, pagination: {}, sort: {} }),
                            dataProvider.getList('promotion/downpayments/general/active', { filter: {}, pagination: {}, sort: {} }),
                            dataProvider.getList('currency', { filter: {}, pagination: {}, sort: {} }),
                            dataProvider.getList('downpayment/deadline', { filter: {}, pagination: {}, sort: {} }),
                            dataProvider.getList('downpayment/payments', { filter: {}, pagination: {}, sort: {} }),
                            dataProvider.getList('realstatetype/', { filter: {}, pagination: {}, sort: {} })
                        ]);

                        var campaigns = campaignsData.data.map(item => ({ key: item.id, value: item.name }));
                        setCampaigns(campaigns);
                        if (campaigns.length > 0)
                            setCampaign(data.campaignId);

                        var channels = channelsData.data.map(item => ({ key: item.id, value: item.name }));
                        setChannels(channels);
                        if (channels.length > 0)
                            setChannel(data.channelId);

                        var promotions = promotionsData.data.map(item => ({ key: item.id, value: item.name }));
                        setPromotions(promotions);
                        if (promotions.length > 0)
                            setPromotion(data.promotionId);

                        var currencies = currenciesData.data.map(item => ({ key: item.id, value: item.name }));
                        setCurrencies(currencies);
                        if (currencies.length > 0)
                            setCurrency(data.currencyId.toString());

                        var deadlines = deadlinesData.data.map(item => ({ key: item.id, value: item.name }));
                        setDeadlines(deadlines);
                        if (deadlines.length > 0)
                            setDeadline(data.deadlineId);

                        var payments = paymentsData.data.map(item => ({ key: item.id, value: item.name }));
                        setPayments(payments);
                        if (payments.length > 0)
                            setPayment(data.paymentTypeId);

                        var types = typesData.data.map(item => ({ id: item.id, name: item.description }));
                        setTypes(types);

                    } else {
                        data.initialPrice = parseFloat(data.initialPrice).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                        data.actualPrice = parseFloat(data.actualPrice).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                        data.reserveAmount = parseFloat(data.reserveAmount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                        data.downPaymentBalance = parseFloat(data.downPaymentBalance).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                        data.commissionGenerated = parseFloat(data.commissionGenerated).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                        data.commissionEarned = parseFloat(data.commissionEarned).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                        data.commissionPaid = parseFloat(data.commissionPaid).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                        data.commissionToPay = parseFloat(data.commissionToPay).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });

                        setValues(data);
                        setCustomerInvoice(data.customerInvoce)

                        var info = data.paymentPlan.map((item, index) => ({
                            id: index,
                            installment: item.correlative,
                            date: item.paidDateInfo,
                            amount: parseFloat(item.committedAmount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }),
                            paidAmount: parseFloat(item.paidAmount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }),
                            balance: parseFloat(item.balance).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }),
                            state: item.state,
                        }));

                        setColumnsPlan([
                            {
                                key: "installment",
                                name: "Cuota",
                                editable: false
                            },
                            {
                                key: "date",
                                name: "Fecha",
                                editable: false
                            },
                            {
                                key: "amount",
                                name: "Monto Comprometido",
                                editable: false
                            },
                            {
                                key: "paidAmount",
                                name: "Monto Pagado",
                                editable: false
                            },
                            {
                                key: "balance",
                                name: "Saldo",
                                editable: false
                            },
                            {
                                key: "state",
                                name: "Estado",
                                editable: false
                            },
                        ])

                        setPaymentPlan(info);

                        var info2 = data.payments.map((item, index) => ({
                            id: index,
                            date: item.paidDateInfo,
                            amount: parseFloat(item.amount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }),
                            origin: item.origin,
                            paidTypeDescription: item.paidTypeDescription,
                            operation: item.operation,
                            receiptNumber: item.receiptNumber,
                            applicationDate: item.applicationDateInfo,
                            user: item.user,
                            create: item.createInfo,
                            key: item.correlative
                        }));

                        setColumns([
                            {
                                key: "date",
                                name: "Fecha",
                                editable: false
                            },
                            {
                                key: "amount",
                                name: "Monto",
                                editable: false
                            },
                            {
                                key: "origin",
                                name: "Origen",
                                editable: false
                            },
                            {
                                key: "paidTypeDescription",
                                name: "Tipo de Pago",
                                editable: false
                            },
                            {
                                key: "operation",
                                name: "Operación",
                                editable: false
                            },
                            {
                                key: "receiptNumber",
                                name: "No. Recibo",
                                editable: false
                            },
                            {
                                key: "applicationDate",
                                name: "Fecha de Aplicación",
                                editable: false
                            },
                            {
                                key: "user",
                                name: "Usuario",
                                editable: false
                            },
                            {
                                key: "create",
                                name: "Creación",
                                editable: false
                            },
                        ]);

                        setPayments(info2);
                    }

                    setOpacity(1);
                    setDisable(false);
                    setDisplayLoading('none');
                }
                else {
                    setAuth(false);
                    setOpenerror(true);
                    setOpacity(1);
                    setDisable(false);
                    setDisplayLoading('none');
                }
            }
            catch (error) {
                console.log(error);
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
            }
        })();
    }, [dataProvider, history.location.pathname, props]);

    function rowKeyGetter(row) {
        var id = payments[row].key;
        console.log(id);
        if (id) {
            history.push('/downpayment/general/payment/' + id + '/show');
        }
    }

    function UpdatePre() {

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        let error = 0;
        if (!Validate(amount, { money: true, required: true }))
            error++;

        if (!Validate(reserve, { money: true, required: true }))
            error++;

        if (!Validate(commission, { money: true, required: true }))
            error++;

        if (!Validate(customer, { required: true })) {
            setError(true);
            error++;
        }

        if (!Validate(realstate, { required: true })) {
            setErrorRealState(true);
            error++;
        }

        if (!Validate(date, { required: true })) {
            setErrorDate(true);
            error++;
        }

        if (!Validate(firstPayment, { required: true })) {
            setErrorFirstPayment(true);
            error++;
        }

        if (error > 0) {
            setMessage("No se pudo actualizar el Enganche")
            handleClickError();
            return;
        }

        var info = {
            customer: customerId.toString(),
            realState: realstateId.toString(),
            product: product.toString(),
            agency: agency?.toString(),
            advisor: advisor?.toString(),
            collector: collector?.toString(),
            campaign: campaign?.toString(),
            channel: channel?.toString(),
            promotion: promotion?.toString(),
            currency: currency?.toString(),
            deadline: deadline?.toString(),
            payment: payment?.toString(),
            installments: installments.toString(),
            amount: amount.replace(/[^0-9.-]/g, ''),
            reserve: reserve.replace(/[^0-9.-]/g, ''),
            commission: commission.replace(/[^0-9.-]/g, ''),
            date,
            firstPayment,
            state: globalState,
            correlative: correlative.toString(),
            control: control.toString()
        }

        dataProvider.update('downpayment', { id: props.id, data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('Enganche actualizado correctamente');
                handleClick();
                setControl(response.data.control);
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo actualizar el Enganche. " + error.toString().replace('HttpError: ', ''));
                handleClickError();
            })

    }

    function UpdateGenerate() {

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            customer: customerId.toString(),
            amount: price.replace(/[^0-9.-]/g, ''),
            date,
            correlative: correlative.toString(),
        }

        dataProvider.update('downpayment/generate', { id: props.id, data: info })
            .then(async () => {
                let data = await dataProvider.getOne(props.resource, { id: props.id });
                data = data.data;

                data.initialPrice = parseFloat(data.initialPrice).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.actualPrice = parseFloat(data.actualPrice).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.reserveAmount = parseFloat(data.reserveAmount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.downPaymentBalance = parseFloat(data.downPaymentBalance).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.commissionGenerated = parseFloat(data.commissionGenerated).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.commissionEarned = parseFloat(data.commissionEarned).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.commissionPaid = parseFloat(data.commissionPaid).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.commissionToPay = parseFloat(data.commissionToPay).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });

                setValues(data);

                var info = data.paymentPlan.map((item, index) => ({
                    id: index,
                    installment: item.correlative,
                    date: item.paidDateInfo,
                    amount: parseFloat(item.committedAmount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }),
                    paidAmount: parseFloat(item.paidAmount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }),
                    balance: parseFloat(item.balance).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }),
                    state: item.state,
                }));

                setColumnsPlan([
                    {
                        key: "installment",
                        name: "Cuota",
                        editable: false
                    },
                    {
                        key: "date",
                        name: "Fecha",
                        editable: false
                    },
                    {
                        key: "amount",
                        name: "Monto Comprometido",
                        editable: false
                    },
                    {
                        key: "paidAmount",
                        name: "Monto Pagado",
                        editable: false
                    },
                    {
                        key: "balance",
                        name: "Saldo",
                        editable: false
                    },
                    {
                        key: "state",
                        name: "Estado",
                        editable: false
                    },
                ])

                setPaymentPlan(info);

                var info2 = data.payments.map((item, index) => ({
                    id: index,
                    date: item.paidDateInfo,
                    amount: parseFloat(item.amount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }),
                    origin: item.origin,
                    paidTypeDescription: item.paidTypeDescription,
                    operation: item.operation,
                    receiptNumber: item.receiptNumber,
                    applicationDate: item.applicationDateInfo,
                    user: item.user,
                    create: item.createInfo,
                    key: item.correlative
                }));

                setColumns([
                    {
                        key: "date",
                        name: "Fecha",
                        editable: false
                    },
                    {
                        key: "amount",
                        name: "Monto",
                        editable: false
                    },
                    {
                        key: "origin",
                        name: "Origen",
                        editable: false
                    },
                    {
                        key: "paidTypeDescription",
                        name: "Tipo de Pago",
                        editable: false
                    },
                    {
                        key: "operation",
                        name: "Operación",
                        editable: false
                    },
                    {
                        key: "receiptNumber",
                        name: "No. Recibo",
                        editable: false
                    },
                    {
                        key: "applicationDate",
                        name: "Fecha de Aplicación",
                        editable: false
                    },
                    {
                        key: "user",
                        name: "Usuario",
                        editable: false
                    },
                    {
                        key: "create",
                        name: "Creación",
                        editable: false
                    },
                ]);

                setPayments(info2);
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('Enganche actualizado correctamente');
                handleClick();
                setGlobalState(data.globalState);
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo actualizar el Enganche. " + error.toString().replace('HttpError: ', ''));
                handleClickError();
            })
    }

    const Delete = async () => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        dataProvider.delete('downpayment', { id: props.id })
            .then(() => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                notify('Enganche eliminado correctamente')
                history.push('/downpayment/general');
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo eliminar el Enganche. " + error.toString());
                handleClickError()
            })
    }

    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();

    const onChangeAgency = async (props) => {
        try {
            const [productsData, advisorsData, collectorsData] = await Promise.all([
                dataProvider.getList('products/downpayments/general/active', { filter: { agency: props.target.value }, pagination: {}, sort: {} }),
                dataProvider.getList('advisors/downpayments/general/assigned', { filter: { agency: props.target.value }, pagination: {}, sort: {} }),
                dataProvider.getList('collectors/downpayments/general/active', { filter: { agency: props.target.value }, pagination: {}, sort: {} })
            ]);

            var products = productsData.data.map(item => ({ key: item.id, value: item.name }));
            setProducts(products);
            if (products.length > 0) {
                setProduct(products[0].key);
                setDisabledRealState(false);
                States.setProductDownPayment(products[0].key);
            } else {
                setDisabledRealState(true);
            }

            var advisors = advisorsData.data.map(item => ({ key: item.id, value: item.name }));
            setAdvisors(advisors);
            if (advisors.length > 0)
                setAdvisor(advisors[0].key);

            var collectors = collectorsData.data.map(item => ({ key: item.id, value: item.name }));
            setCollectors(collectors);
            if (collectors.length > 0)
                setCollector(collectors[0].key);

        } catch (error) {

        }
    }

    const onChangeProduct = async (props) => {
        States.setProductDownPayment(props.target.value);
    }

    const onClickRealState = async (e) => {
        setCloseDialogRealState(true);
        let record = await dataProvider.getOne('realstate', { id: e });
        setRealState(record.data.realState);
        setRealStateId(record.data.id);
        setPrice(parseFloat(record.data.price).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }));
        setErrorRealState(false);
    }

    const onClick = async (e) => {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');
        setCloseDialog(true);
        let record = await dataProvider.getOne('customer/downpayment', { id: e });
        setValues(values);
        setError(false);

        var info = {
            customer: record.data.id.toString(),
        }

        dataProvider.update('downpayment/customerinvoice', { id: props.id, data: info })
            .then(async () => {
                setCustomerInvoice(record.data.name);
                values.customerInvoceCUI = record.data.cui.toString();
                values.customerInvoceNIT = record.data.nit.toString();
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('Enganche actualizado correctamente');
                handleClick();
            })
            .catch((error) => {
                setMessage("No se pudo actualizar cliente del Enganche. " + error.toString().replace('HttpError: ', ''));
                handleClickError();
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
            })
    }

    const Customers = () => {
        const PostFilter = [
            <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Cliente"}</span>} source="customer" alwaysOn />,
            <TextInput InputProps={{ disableUnderline: true }} label={<span>{"NIT"}</span>} source="nit" alwaysOn />,
            <TextInput InputProps={{ disableUnderline: true }} label={<span>{"CUI"}</span>} source="cui" alwaysOn />,
        ];
        let props = { basePath: '/customer/downpayment', resource: 'customer/downpayment', hasCreate: false, hasEdit: false, hasList: true, hasShow: false };
        return (
            <List perPage={5} {...props} aside={<FilterSidebar {...props} filters={PostFilter} />} actions={<ListActions />} bulkActionButtons={false} pagination={<PostPagination noPerPage={true} />}>
                <Datagrid rowClick={onClick}>
                    <TextField source="name" label="Cliente" sortable={false} style={Style.fieldList} cellClassName={classes.fields} />
                    <TextField source="nit" label="NIT" sortable={false} style={Style.fieldList} cellClassName={classes.fields} />
                    <TextField source="cui" sortable={false} label="CUI" style={Style.fieldList} cellClassName={classes.fields} />
                </Datagrid>
            </List>
        );
    }

    const RealStates = () => {
        const PostFilter = [
            <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Inmueble"}</span>} source="realState" alwaysOn />,
            <SelectInput InputProps={{ disableUnderline: true }} label={<span >{"Tipo"}</span>} source="type" choices={types} alwaysOn />,
        ];

        let props = { basePath: '/realstate/downpayments/product', resource: 'realstate/downpayments/product', hasCreate: false, hasEdit: false, hasList: true, hasShow: false };

        return (
            <List perPage={5} {...props} aside={<FilterSidebar {...props} filters={PostFilter} />} actions={<ListActions />} bulkActionButtons={false} pagination={<PostPagination noPerPage={true} />}>
                <Datagrid rowClick={onClickRealState}>
                    <TextField source="realState" style={Style.fieldList} label="Inmueble" sortable={false} cellClassName={classes.fields} />
                    <TextField source="typeRealState" style={Style.fieldList} label="Tipo" sortable={false} cellClassName={classes.fields} />
                    <NumberField source="price" style={Style.fieldList} label="Precio" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                </Datagrid>
            </List>
        );
    }

    let search = <SimpleDialog title="Clientes" body={<Customers />} maxWidth='md' close={closeDialog} setClose={setCloseDialog} />;
    let searchRealStae = <SimpleDialog title="Inmuebles" body={<RealStates />} maxWidth='md' close={closeDialogRealState} setClose={setCloseDialogRealState} disabled={disabledRealState} />;

    const handleRedirectSettle = () => {
        history.push(`/downpayment/settlement`);
    };

    const handleRedirectGeneral = () => {
        history.push(`/downpayment/general`);
    };

    const [openDownPayments, setOpeDownPayments] = useState(true);

    const handleClickOpenDownPayments = () => {
        setOpeDownPayments(!openDownPayments);
    };

    const menus = [{
        icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
        open: openDownPayments,
        openAction: handleClickOpenDownPayments,
        actions: [
            { icon: <img src={general} alt="logo" style={Style.MenuLogo} />, name: 'General', onClick: handleRedirectGeneral },
            { icon: <img src={settle} alt="logo" style={Style.MenuLogo} />, name: 'Liquidaciones', onClick: handleRedirectSettle },
        ]
    }]

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {auth ? (
                <div class="edit">
                    {globalState === 'PRE' ? (
                        <div>
                            <div class="Loading" style={{ display: displayLoading }}>
                                <CircularProgress size="15rem" />
                            </div>
                            <div class="container" style={{ opacity: opacity }}>
                                <div class="row">
                                    <InputFieldCustom readOnly={true} error={error} col="col-6" width="95%" value={customer} placeholder="Cliente" label="Cliente*" required type="text" />
                                    <InputFieldCustom readOnly={!authUpdate} error={errorDate} setError={setErrorDate} value={date} setValue={setDate} col="col-6" width="95%" placeholder="Fecha Reserva" label="Fecha Reserva" required type="date" />
                                </div>
                                <div class="row">
                                    <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setAgency} value={agency} options={agencies} component="select" label="Agencias" onChange={onChangeAgency} />
                                    <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setProduct} value={product} options={products} component="select" label="Productos" onChange={onChangeProduct} />
                                </div>
                                <div class="row">
                                    <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setAdvisor} value={advisor} options={advisors} component="select" label="Asesores" />
                                    <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setCollector} value={collector} options={collectors} component="select" label="Cobradores" />
                                </div>
                                <div class="row">
                                    <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setCampaign} value={campaign} options={campaigns} component="select" label="Campañas" />
                                    <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setChannel} value={channel} options={channels} component="select" label="Canales" />
                                </div>
                                <div class="row">
                                    <InputFieldCustom readOnly={true} error={errorRealState} setError={setErrorRealState} col="col-6" width="100%" value={realstate} setValue={setRealState} placeholder="Inmueble" label="Inmueble*" required type="text" component="search" search={searchRealStae} />
                                    <InputFieldCustom readOnly={true} value={price} col="col-6" width="95%" placeholder="Precio Inmueble" label="Precio Inmueble (Q) *" required type="text" money />
                                </div>
                                <div class="row">
                                    <InputFieldCustom readOnly={!authUpdate} error={errorFirstPayment} setError={setErrorFirstPayment} value={firstPayment} setValue={setFirstPayment} col="col-6" width="95%" placeholder="Fecha Primer Pago" label="Fecha Primer Pago" required type="date" />
                                    <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setPayment} value={payment} options={payments} component="select" label="Pagos" />
                                </div>
                                <div class="row">
                                    <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setPromotion} value={promotion} options={promotions} component="select" label="Promociones" />
                                    <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setCurrency} value={currency} options={currencies} component="select" label="Monedas" />
                                </div>
                                <div class="row">
                                    <InputFieldCustom readOnly={!authUpdate} col="col-6" width='95%' setValue={setDeadline} value={deadline} options={deadlines} component="select" label="Plazos" />
                                    <InputFieldCustom readOnly={!authUpdate} value={installments} setValue={setInstallments} col="col-6" width="95%" placeholder="Cuotas" label="Cuotas" required type="number" />
                                </div>
                                <div class="row">
                                    <InputFieldCustom readOnly={!authUpdate} value={amount} setValue={setAmount} col="col-6" width="95%" placeholder="Monto Enganche" label="Saldo Enganche (Q) *" required type="text" money />
                                    <InputFieldCustom readOnly={!authUpdate} value={reserve} setValue={setReserve} col="col-6" width="95%" placeholder="Reserva" label="Reserva (Q) *" required type="text" money />
                                </div>
                                <div class="row">
                                    <InputFieldCustom readOnly={!authUpdate} value={commission} setValue={setCommission} col="col-6" width="95%" placeholder="Comisión Pagada" label="Comisión Pagada (Q) *" required type="text" money />
                                </div>
                                <BottomToolBar buttons={[{ auth: authUpdate, action: UpdatePre, name: 'Guardar' }, { auth: authDelete, action: Delete, name: 'Eliminar' }, { auth: true, action: UpdateGenerate, name: 'Generar' }]} disabled={disable} menu={<InternalMenu menus={menus} />} />
                            </div>
                        </div>
                    ) :
                        !isSmall ? (
                            <div>
                                <div class="Loading" style={{ display: displayLoading }}>
                                    <CircularProgress size="15rem" />
                                </div>
                                <div class="container" style={{ opacity: opacity }}>
                                    <div class="row">
                                        <InputFieldCustom error={error} setError={setError} col="col-4" width="100%" value={customerInvoice} setValue={setCustomerInvoice} placeholder="Cliente Factura" label="Cliente Factura*" required type="text" component="search" readOnly={true} search={search} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.customerInvoceNIT} label="NIT Cliente Factura" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.customerInvoceCUI} label="CUI Cliente Factura" readOnly={true} />
                                    </div>
                                    <div class="row">
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.client} label="Cliente" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.nitClient} label="NIT Cliente" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.cuiClient} label="CUI Cliente" readOnly={true} />
                                    </div>
                                    <div class="row">
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.realState} label="Inmueble" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="date" value={values?.downPaymentStartDateInfo} label="Inicio" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="date" value={values?.downPaymentEndDateInfo} label="Fin" readOnly={true} />
                                    </div>
                                    <div class="row">
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.installment} label="Cuotas" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.initialPrice} label="Precio Inicial" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.discountAmount} label="Monto Descuento" readOnly={true} />
                                    </div>
                                    <div class="row">
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="number" value={values?.amount} label="Monto" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.actualPrice} label="Precio Actual" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.reserveAmount} label="Monot Reserva" readOnly={true} />
                                    </div>
                                    <div class="row">
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.downPaymentBalance} label="Saldo" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.commissionGenerated} label="Comisión Generada" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.commissionEarned} label="Comisión Devengada" readOnly={true} />
                                    </div>
                                    <div class="row">
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.commissionPaid} label="Comisión Pagada" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.commissionToPay} label="Comisión por Pagar" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.commission} label="Comisión" readOnly={true} />
                                    </div>
                                    <div class="row">
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.globalState} label="Estado Global" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.state} label="Estado" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.advisor} label="Asesor" readOnly={true} />
                                    </div>
                                    <div class="row">
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.agency} label="Inmobiliaria" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.product} label="Producto" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.channel} label="Canal" readOnly={true} />
                                    </div>
                                    <div class="row">
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.campaign} label="Campaña" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.promotion} label="Promoción" readOnly={true} />
                                        <InputFieldCustom col="col-4" width='100%' bordercolor={'#EFEFEF'} type="text" value={values?.collector} label="Cobrador" readOnly={true} />
                                    </div>
                                    <div class="row" style={{ paddingTop: '1.2em' }}>
                                        <div class="col-4">
                                            <label style={Style.Label}>Plan de Pagos</label>
                                        </div>
                                    </div>
                                    <div class="row" style={Style.DataGridCustome}>
                                        <div clas="col-12" style={Style.AllWidth}>
                                            <div className={classes.root}>
                                                <ReactDataGrid
                                                    columns={columnsPlan}
                                                    rowGetter={i => paymentPlan[i]}
                                                    rowsCount={paymentPlan.length}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" style={{ paddingTop: '1.2em' }}>
                                        <div class="col-4">
                                            <label style={Style.Label}>Pagos</label>
                                        </div>
                                        <div class="col-7"></div>
                                    </div>
                                    <div class="row" style={Style.DataGridCustome}>
                                        <div clas="col-12" style={Style.AllWidth}>
                                            <div className={classes.root}>
                                                <ReactDataGrid
                                                    columns={columns}
                                                    rowGetter={i => payments[i]}
                                                    rowsCount={payments.length}
                                                    onRowClick={rowKeyGetter}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <BottomToolBar buttons={[{ auth: authDelete, action: Delete, name: 'Eliminar' }]} disabled={disable} menu={<InternalMenu menus={menus} />} />
                                    <br />
                                </div>
                            </div>
                        ) : (
                            <div class="container">
                                <h1>Down Payment</h1>
                            </div>)}

                    <div class="row" style={Style.Alert}>
                        <div>
                            <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                <div>
                                    <Alert onClose={handleCloseSnack} severity="success" className={Style.AlertMessage} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" style={Style.AlertMessage} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
        </div>
    )
}

export default UpdateDownPayment