import { useRecordContext } from 'react-admin';
import Divider from '@mui/material/Divider';
import { StyleTheme, Style } from '../Commons/Styles.jsx'; 
import { createStyles, makeStyles } from '@material-ui/core';

const DownPaymentsFailShow = () => {
    const record = useRecordContext();
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    if (!record) return null;
    return (
        <div class="container">
            <div class="row">
                <div class="col-3">
                    <h2 className={classes.dataTitle}> Monto Reserva: GTQ {parseFloat(record.reserveAmount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 })}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Monto Enganche: GTQ {parseFloat(record.downPaymentAmount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 })}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Monto Enganche Recibido: GTQ {parseFloat(record.downPaymentAmountReceived).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 })}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Monto Enganche Liquidado: GTQ {parseFloat(record.downPaymentAmountSettled).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 })}</h2>
                </div>
            </div>
            <Divider />
            <div class="row" style={Style.ExpandListRow}>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Comisión Pagada: GTQ {parseFloat(record.commissionPaid).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 })}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Fecha de Baja:  <input type='date' style={Style.ExpandListDate} value={record.downDate} readOnly /></h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Asesor:  {record.advisor}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Agencia:  {record.agency}</h2>
                </div>
            </div>
            <Divider />
            <div class="row" style={Style.ExpandListRow}>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Producto:  {record.product}</h2>
                </div>
            </div>
        </div>
    );
};

export default DownPaymentsFailShow;