import { Admin, Resource, fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import withValidations from "./withValidations";
import MyLayout from './MyLayout';
import { Route } from 'react-router-dom';
import { useState } from 'react';

import CompaniesList from './Components/CompaniesAdminstration/CompaniesList';
import CompanieShow from './Components/CompaniesAdminstration/CompaniesShow';

import CompaniesOperationsList from './Components/CompaniesOperations/CompaniesList';
import CompanieOperationsShow from './Components/CompaniesOperations/CompaniesShow';

import CompaniesReportsList from './Components/CompaniesReports/CompaniesList';
import CompanieReportsShow from './Components/CompaniesReports/CompaniesShow';

import RealStateList from './Components/RealState/RealStateList';
import RealStateEdit from './Components/RealState/RealStateShow';
import RealStateCreate from './Components/RealState/RealStateCreate';

import RealStateTypeList from './Components/RealStateType/RealStateTypeList';
import RealStateTypeEdit from './Components/RealStateType/RealStateTypeShow';
import RealStateTypeCreate from './Components/RealStateType/RealStateTypeCreate';

import DownPaymentToBeSettlemntList from './Components/DownPaymentToBeSettlement/DownPaymentList';
import DownPaymentToBeSettlemntShow from './Components/DownPaymentToBeSettlement/DownPaymentShow';

import DownPaymentsGeneralList from './Components/DownPaymentsGeneral/DownPaymentList';
import DownPaymentsGeneralShow from './Components/DownPaymentsGeneral/DownPaymentShow';
import DownPaymentsGeneralCreate from './Components/DownPaymentsGeneral/DownPaymentCreate';

import SettlementDownPaymentShow from './Components/SettlementsDownPayment/SettlementDownPaymentShow';
import SettlementDownPaymentCreate from './Components/SettlementsDownPayment/SettlementDownPaymentCreate';

import ReportsDownPaymentsFail from './Components/Reports/DownPaymentsFail';
import ReportsDownPaymentsPortfolio from './Components/Reports/DownPaymentsPortfolio';
import ReportsDownPaymentsPayment from './Components/Reports/DownPaymentsPayment';
import ReportsDownPaymentsAnalysis from './Components/Reports/DownPaymentsAnalysis';

import LogOut from './Components/Commons/Logout';

import States from './Components/Commons/States';

const routes = [
  <Route exact path="/logout" component={LogOut} />,
  <Route exact path="/reports/downpayment/analysis" component={ReportsDownPaymentsAnalysis} />
];

const domain = process.env.REACT_APP_URL_API ?? "";

const App = () => {
  const [module, setModule] = useState('Admin');

  const { getIdTokenClaims } = useAuth0();

  const fetchJson = async (url, options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
    }
    const customHeaders = await (async () => {
      var c = await getIdTokenClaims();
      States.setUser(c?.nickname);
      return {
        user: c?.sub,
        username: c?.nickname,
        company: States.getCompany(),
        organization: States.getOrganizationId(),
        nit: States.getNIT(),
        productDownPayment : States.getProductDownPayment(),
      }
    })();

    for (var header in customHeaders) {
      options.headers.set(header, customHeaders[header]);
    }

    return fetchUtils.fetchJson(url, options);
  }

  const dataProvider = simpleRestProvider(domain, fetchJson)

  const myDataProvider = {
    ...dataProvider,
    delete: async (resource, params) => {
      const { id, previousData } = params;
      var c = await getIdTokenClaims();
      const url = `${domain}/${resource}/${id}`;
      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          user: c?.sub,
          username: c?.nickname,
          company: States.getCompany(),
          organization: States.getOrganizationId(),
          nit: States.getNIT()
        },
        body: JSON.stringify(previousData),
      };

      return fetch(url, options)
        .then(async response => {
          if (!response.ok) {
            const error = await response.text();
            throw new Error(JSON.parse(error).error);
          }
          return response.json();
        })
        .then(json => ({
          data: json,
        }));
    },
    auth: async (resource, params) => {
      const { id, data } = params;
      var c = await getIdTokenClaims();
      const url = `${domain}/${resource}/${id}?action=${data.action}`;
      const options = {
        method: 'GET',
        headers: {
          user: c?.sub,
          username: c?.nickname,
          company: States.getCompany(),
          organization: States.getOrganizationId(),
          nit: States.getNIT()
        },
      };

      return fetch(url, options)
        .then(response => {
          if (!response.ok) {
            throw new Error(response.status);
          }
          return response.json();
        })
        .then(json => ({
          data: json,
        }));
    },
  };

  return (
    <Admin
      layout={props => <MyLayout {...props} module={module} />}
      dataProvider={myDataProvider}
      customRoutes={routes}
    >
      <Resource name="company/administration" list={props => <CompaniesList {...props} setModule={setModule} />} edit={CompanieShow} />
      <Resource name="company/operations" list={props => <CompaniesOperationsList {...props} setModule={setModule} />} edit={CompanieOperationsShow} />
      <Resource name="company/reports" list={props => <CompaniesReportsList {...props} setModule={setModule} />} edit={CompanieReportsShow} />
      <Resource name="realstate" list={RealStateList} edit={RealStateEdit} create={RealStateCreate} />
      <Resource name="realstatetype" list={RealStateTypeList} edit={RealStateTypeEdit} create={RealStateTypeCreate} />
      <Resource name="downpayment/settlement" list={DownPaymentToBeSettlemntList} show={DownPaymentToBeSettlemntShow} />
      <Resource name="downpayment/general" list={DownPaymentsGeneralList} show={DownPaymentsGeneralShow} create={DownPaymentsGeneralCreate}/>
      <Resource name="settlement/downpayment" edit={SettlementDownPaymentShow} create={SettlementDownPaymentCreate} />
      <Resource name="reports/downpayment/fail" list={ReportsDownPaymentsFail} />
      <Resource name="reports/downpayment/portfolio" list={ReportsDownPaymentsPortfolio} />
      <Resource name="reports/downpayment/payments" list={ReportsDownPaymentsPayment} />
      <Resource name="customer/downpayment"/>
      <Resource name="realstate/downpayments/product"/>
    </Admin>
  )
}

export default withAuthenticationRequired(withValidations(App));