import { useListContext, sanitizeListRestProps, useDataProvider, TopToolbar } from 'react-admin';
import { useMediaQuery, createTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as XLSX from 'xlsx';
import { Style } from '../Commons/Styles.jsx';

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    create,
    catalog,
    download,
    calendar,
    ...rest
  } = props;

  const {
    currentSort,
    resource,
    filterValues,
    basePath,
  } = useListContext();

  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const dataProvider = useDataProvider();

  async function exportToExcel(filename, resource, sort, filterValues) {
    let data = await dataProvider.getList(resource, { pagination: { page: 1, perPage: 1000 }, sort, filter: filterValues });
    console.log(data);
    let columns = [];
    for (let key in data.data[0]) {
      columns.push(key)
    }
    const ws = XLSX.utils.json_to_sheet(data.data, { header: columns });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  return (
    <TopToolbar  {...sanitizeListRestProps(rest)}>
      {!isSmall && download ? (
        <Button
          onClick={() => exportToExcel('data', resource, currentSort, filterValues)}
          style={Style.ToolBarButton}>
          Exportar
        </Button>
      ) : (
        null
      )}
      {create ? (
        <Button
          href={'#' + basePath + '/create'}
          color="primary"
          style={Style.ToolBarButton}>
          Crear
        </Button>
      ) : (null)}
    </TopToolbar>
  );
};

export default ListActions;