import { AppBar } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import MyUserMenu from './MyUserMenu';

const useAppBarStyles = makeStyles(() => ({

  toolbar: {
    backgroundColor: 'white',
    color: 'black'
  },
  menuButton: {
    color: 'black',

  },
  menuButtonIconClosed: {
  },
  menuButtonIconOpen: {
  },
  title: {
    textIndent: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}));

const MyAppBar = props => {
  const classes = useAppBarStyles();
  const { module } = props;
  return (
    <AppBar {...props} classes={classes} userMenu={<MyUserMenu module ={module}/>}>
    </AppBar>
  );
};

export default MyAppBar;