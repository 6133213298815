import { useRecordContext } from 'react-admin';
import Divider from '@mui/material/Divider';
import { StyleTheme, Style } from '../Commons/Styles.jsx'; 
import { createStyles, makeStyles } from '@material-ui/core';

const DownPaymentsPaymentShow = () => {
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const record = useRecordContext();
    
    if (!record) return null;
    return (
        <div class="container">
            <div class="row">
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Crédito:  {record.creditNumber}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Correlativo Pago:  {record.correlative}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Origen:  {record.origin}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Fecha Creación:  <input type='date' style={Style.ExpandListDate} value={record.dateCreationInfo} readOnly /></h2>
                </div>
            </div>
            <Divider />
            <div class="row" style={Style.ExpandListRow}>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Asesor:  {record.advisor}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Agencia:  {record.agency}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Producto:  {record.product}</h2>
                </div>
                <div class="col-3">
                    <h2 className={classes.dataTitle}>Recibo Manual:  {record.receiptNumber}</h2>
                </div>
            </div>
        </div>
    );
};

export default DownPaymentsPaymentShow;