
import { useMediaQuery } from '@material-ui/core';
import { useState, useEffect } from "react";
import { useDataProvider } from "react-admin";
import InputFieldCustom from '../Commons/InputFieldCustom'
import InternalMenu from '../Commons/InternalMenu';
import building from '../Commons/Icons/house-building.svg';
import credits from '../Commons/Icons/creditos.svg';
import house from '../Commons/Icons/home.svg';
import { useHistory } from 'react-router-dom';
import historyTracker from '../Commons/historyTracker';
import States from '../Commons/States';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@mui/material/Typography';
import AuthNotification from '../Commons/AuthNotification';
import BreadCrumbCustom from '../Commons/BreadCrum';
import { Style } from '../Commons/Styles.jsx';
import BottomToolBar from '../Commons/BottomToolBar';

const RequestShow = (props) => {
    const [openerror, setOpenerror] = useState(false);
    const [auth, setAuth] = useState(false);
    const [state, setState] = useState({});
    const history = useHistory();
    const dataProvider = useDataProvider();
    let isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const handleRedirectRealState = () => {
        history.push(`/realstate`);
    };

    const handleRedirectRealStateType = () => {
        history.push(`/realstatetype`);
    };

    const [openDownPayments, setOpeDownPayments] = useState(true);

    const handleClickOpenDownPayments = () => {
        setOpeDownPayments(!openDownPayments);
    };

    const menus = [{
        icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
        open: openDownPayments,
        openAction: handleClickOpenDownPayments,
        actions: [
            { icon: <img src={house} alt="logo" style={Style.MenuLogo} />, name: 'Inmuebles', onClick: handleRedirectRealState },
            { icon: <img src={building} alt="logo" style={Style.MenuLogo} />, name: 'Tipo Inmuebles', onClick: handleRedirectRealStateType },
        ]
    }]

    useEffect(() => {
        (async () => {
            try {
                let record = await dataProvider.getOne(props.resource, { id: props.id });
                historyTracker.addPath(history.location.pathname, record.data.name, 1);
                States.setNIT(record.data.nit);
                if (record.data.nit) {
                    const { data } = await dataProvider.auth('Auth', { id: 'Company', data: { action: 'read' } });
                    if (data.length > 0) {
                        setAuth(data[0]);
                        setOpenerror(!data[0]);
                        if (data[0]) {
                            setState(record.data);
                            States.setCompany(record.data.company);
                        }
                    } else {
                        setAuth(false);
                        setOpenerror(true)
                    }
                }
            }
            catch {
            }
        }
        )();
    }, [dataProvider, history, props]);

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {auth ? (
                <div class="edit">
                    {!isSmall ? (
                        <div class="container">
                            <div class="row">
                                <div class="col-3">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled
                                                checked={state?.creditSalesParameters}
                                                color="primary"
                                            />}
                                        label={
                                            <Typography style={Style.Flags}>Ventas Créditos
                                            </Typography>
                                        }
                                    />
                                </div>
                                <div class="col-3">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled
                                                checked={state?.dispatch}
                                                color="primary"
                                            />}
                                        label={
                                            <Typography style={Style.Flags}>Despacho
                                            </Typography>
                                        }
                                    />
                                </div>
                                <div class="col-3">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled
                                                checked={state?.overPricing}
                                                color="primary"
                                            />}
                                        label={
                                            <Typography style={Style.Flags}>Sobre Precio
                                            </Typography>
                                        }
                                    />
                                </div>
                                <div class="col-3">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled
                                                checked={state?.reservItems}
                                                color="primary"
                                            />}
                                        label={
                                            <Typography style={Style.Flags}>Reservar Artículos
                                            </Typography>
                                        }
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.company} label="Código" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.name} label="Nombre" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.nit} label="NIT" readOnly={true} />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.address} label="Dirección" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.address2} label="Dirección 2" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.phone} label="Teléfono" readOnly={true} />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.manager} label="Gerente" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.email} label="Correo Notificador" readOnly={true} />
                            </div>
                            <BottomToolBar menu={<InternalMenu menus={menus} />} />
                            <br/>
                        </div>
                    ) : (
                        <div class="container">
                        </div>
                    )}
                </div>) : (<AuthNotification openerror={openerror} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)
            }
        </div>
    );
}

export default RequestShow