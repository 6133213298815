import { useEffect, useState } from "react";
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useMediaQuery } from '@material-ui/core';
import { useDataProvider } from "react-admin";
import BreadCrumbCustom from '../Commons/BreadCrum';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import AuthNotification from '../Commons/AuthNotification';
import ReactDataGrid from "react-data-grid";
import Button from '@material-ui/core/Button';
import States from '../Commons/States';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import credits from '../Commons/Icons/creditos.svg';
import settle from '../Commons/Icons/settle.svg';
import general from '../Commons/Icons/gears.svg';
import InternalMenu from '../Commons/InternalMenu';
import BottomToolBar from '../Commons/BottomToolBar';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateDownPayment = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [state, setState] = useState({})
    const [openAuth, setOpenAuth] = useState(false);
    const [auth, setAuth] = useState(false);
    const [authCreate, setAuthCreate] = useState(false);
    const [invoiced, setInvoiced] = useState(true);
    const [details, setDetails] = useState([]);
    const [columns, setColumns] = useState([]);
    const dataProvider = useDataProvider();
    const history = useHistory();
    const [opensnack, setOpensnack] = useState(false);
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleCloseSnack = () => {
        setOpensnack(false);
    };

    const handleClick = () => {
        setOpensnack(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    useEffect(() => {
        (async () => {
            try {
                const { data } = await dataProvider.getOne(props.resource, { id: props.id });
                data.priceRealState = parseFloat(data.priceRealState).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.amountSettlement = parseFloat(data.amountSettlement).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.deductions = parseFloat(data.deductions).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.disbursementBank = parseFloat(data.disbursementBank).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.remaining = parseFloat(data.remaining).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.remainingBalance = parseFloat(data.remainingBalance).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.commission = parseFloat(data.commission).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.commissionEarned = parseFloat(data.commissionEarned).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });
                data.commissionPaid = parseFloat(data.commissionPaid).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 });

                setState(data);
                const authdata = await dataProvider.auth('Auth', { id: 'DownPaymentSettlement', data: { action: 'read' } });
                if (authdata.data[0]) {
                    const currentPath = history.location.pathname;
                    historyTracker.addPath(currentPath, props.id, 3);
                    setAuth(true);
                    const authdata2 = await dataProvider.auth('Auth', { id: 'SettlementDownPayment', data: { action: 'create' } });
                    setAuthCreate(authdata2.data[0]);

                    var info = data.master.map((item, index) => ({
                        id: index,
                        description: item.descriptionTransactionType,
                        amount: parseFloat(item.amount).toLocaleString("es-GT", { style: "decimal", minimumFractionDigits: 2 }),
                        key: item.correlative
                    }));

                    setDetails(info);
                    setInvoiced(data.invoiced === 0);

                    setColumns([
                        {
                            key: "description",
                            name: "Descripción",
                            editable: false
                        },
                        {
                            key: "amount",
                            name: "Monto",
                            editable: false
                        },
                    ])

                } else {
                    setAuth(false);
                    setOpenAuth(true);
                }
            }
            catch (error) {
                console.log(error)
            }
        })();
    }, [dataProvider, history.location.pathname, props]);

    function rowKeyGetter(row) {
        var id = details[row].key;
        if (id) {
            history.push('/settlement/downpayment/' + id + '/show');
        }
    }

    function Create() {
        States.setDownPayment(props.id);
        history.push('/settlement/downpayment/create');
    }

    function Invoice() {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        dataProvider.update('downpayment/settlement/invoice', { id: props.id, data: null })
            .then(() => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('Enganche facturado correctamente');
                setInvoiced(!invoiced);
                handleClick();
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo facturar el enganche. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    function Reverse() {
        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        dataProvider.update('downpayment/settlement/reverse', { id: props.id, data: null })
            .then(() => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage('Facturación del enganche revertida correctamente');
                setInvoiced(!invoiced);
                handleClick();
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo revertir la facturación del enganche. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();

    const handleRedirectSettle = () => {
        history.push(`/downpayment/settlement`);
    };

    const handleRedirectGeneral = () => {
        history.push(`/downpayment/general`);
    };

    const [openDownPayments, setOpeDownPayments] = useState(true);

    const handleClickOpenDownPayments = () => {
        setOpeDownPayments(!openDownPayments);
    };

    const menus = [{
        icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
        open: openDownPayments,
        openAction: handleClickOpenDownPayments,
        actions: [
            { icon: <img src={general} alt="logo" style={Style.MenuLogo} />, name: 'General', onClick: handleRedirectGeneral },
            { icon: <img src={settle} alt="logo" style={Style.MenuLogo} />, name: 'Liquidaciones', onClick: handleRedirectSettle },
        ]
    }]

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {auth ? (
                <div class="edit">
                    {!isSmall ? (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>) : (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>
                    )}
                    {!isSmall ? (
                        <div class="container" style={{ opacity: opacity }}>
                            <div class="row">
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.realState} label="Inmueble" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.client} label="Cliente" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.advisor} label="Asesor" readOnly={true} />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.priceRealState} label="Precio Inmueble" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.amountSettlement} label="Monto Enganche" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.deductions} label="Deducciones" readOnly={true} />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.disbursementBank} label="Desemboloso Bancario" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.remainingBalance} label="Saldo Remanente" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.remaining} label="Remanente" readOnly={true} />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.commission} label="Comisión" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.commissionEarned} label="Comisión Devengada" readOnly={true} />
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.commissionPaid} label="Comisión Pagada" readOnly={true} />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-4" width='100%' type="text" value={state?.state} label="Estado" readOnly={true} />
                            </div>
                            <div class="row" style={{ paddingTop: '1.2em' }}>
                                <div class="col-4">
                                    <label style={Style.Label}>Liquidaciones</label>
                                </div>
                                <div class={authCreate ? "col-6" : "col-7"}></div>
                                {invoiced ? (
                                    <div class="col-1">
                                        <Button
                                            style={Style.ToolBarButton}
                                            onClick={Invoice}
                                            disabled={disable}>
                                            Facturar
                                        </Button>
                                    </div>
                                ) : (
                                    <div class="col-1">
                                        <Button
                                            style={Style.ToolBarButton}
                                            onClick={Reverse}
                                            disabled={disable}>
                                            Revertir
                                        </Button>
                                    </div>)}
                                {authCreate ? (
                                    <div class="col-1">
                                        <Button
                                            style={Style.ToolBarButton}
                                            onClick={Create}
                                            disabled={disable}>
                                            Crear
                                        </Button>
                                    </div>) : (null)
                                }
                            </div>
                            <div class="row" style={Style.DataGridCustome}>
                                <div clas="col-12" style={Style.AllWidth}>
                                    <div className={classes.root}>
                                        <ReactDataGrid
                                            columns={columns}
                                            rowGetter={i => details[i]}
                                            rowsCount={details.length}
                                            enableCellSelect={true}
                                            onRowClick={rowKeyGetter}
                                        />
                                    </div>
                                </div>
                            </div>
                            <BottomToolBar menu={<InternalMenu menus={menus} />} />
                        </div>
                    ) : (
                        <div class="container">
                        </div>)
                    }
                    <div class="row" style={Style.Alert}>
                        <div>
                            <Snackbar open={opensnack} autoHideDuration={4000} onClose={handleCloseSnack}>
                                <div>
                                    <Alert onClose={handleCloseSnack} severity="success" className={Style.AlertMessage} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" style={Style.AlertMessage} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
        </div>
    )
}

export default UpdateDownPayment