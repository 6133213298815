import { useDataProvider } from 'react-admin';
import { useMediaQuery, createTheme } from '@material-ui/core';
import BreadCrumbCustom from '../Commons/BreadCrum';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import historyTracker from '../Commons/historyTracker';
import AuthNotification from '../Commons/AuthNotification';
import States from '../Commons/States';
import { Chart } from "react-google-charts";
import SelectCustom from '../Commons/SelectCustom';
import { Style } from '../Commons/Styles.jsx';
import InternalMenu from '../Commons/InternalMenu';
import credits from '../Commons/Icons/creditos.svg';
import fail from '../Commons/Icons/faild.svg';
import pay from '../Commons/Icons/pay.svg';
import book from '../Commons/Icons/book.svg';
import stats from '../Commons/Icons/stats.svg';
import BottomToolBar from '../Commons/BottomToolBar';

const RequestList = () => {
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const [openAuth, setOpenAuth] = useState(false);
  const [auth, setAuth] = useState(false);
  const dataProvider = useDataProvider();
  const [dataAgency, setDataAgency] = useState({});
  const [dataAgencyActual, setDataAgencyActual] = useState({});
  const [dataAdvisor, setDataAdvisor] = useState({});
  const [dataCompany, setDataCompany] = useState({});
  const [graph1, setGraph1] = useState({});
  const [graph2, setGraph2] = useState({});
  const [graph3, setGraph3] = useState({});
  const [graph4, setGraph4] = useState({});
  const [graph5, setGraph5] = useState({});
  const [graph6, setGraph6] = useState({});
  const [graph7, setGraph7] = useState({});
  const [graph1Title, setGraph1Title] = useState("Ventas Proyectadas / Ventas Netas");
  const [graph2Title, setGraph2Title] = useState("Venta Proyectada / Venta Percibida");
  const [graph3Title, setGraph3Title] = useState("Ventas por Agencias");
  const [graph4Title, setGraph4Title] = useState("Enganches Captados / Enganches por Percibir");
  const [graph5Title, setGraph5Title] = useState("Enganches Pactados / Enganches Percibidos");
  const [graph6Title, setGraph6Title] = useState("Comisiones");
  const [graph7Title, setGraph7Title] = useState("Ventas Netas / Ventas Percibidas");
  const [graph7Enable, setGraph7Enable] = useState(true);
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState(-1);
  const [advisor, setAdvisor] = useState(-1);
  const [advisors, setAdvisors] = useState([]);
  const [advisorsinfo, setAdvisorsInfo] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let { data } = await dataProvider.getOne('reports/downpayment/analysis', { id: States.getCompany() });
        setDataAgency(data.agenciesanalysis);
        setDataCompany(data.companyanalysis);
        setDataAdvisor(data.advisorsanalysis);
        data.agencies.unshift({ value: "  ", key: "-1" });
        setAgencies(data.agencies);
        setAdvisorsInfo(data.advisors);
        let companyanalysis = data.companyanalysis;

        setGraph1([
          ["Ventas Proyectadas / Ventas", "Q"],
          ["Ventas Netas", companyanalysis.totalNetSales],
          ["Por Vender", companyanalysis.totalProjectedSales - companyanalysis.totalNetSales]
        ]);

        setGraph2([
          ["Ventas Proyectadas", "Q"],
          ["Ventas Percibidas", companyanalysis.receivedDownPayments + companyanalysis.settlementAmount],
          ["Ventas por Percibidas", companyanalysis.totalProjectedSales - (companyanalysis.receivedDownPayments + companyanalysis.settlementAmount)]
        ]);

        var graph3 = [
          ["Ventas Netas por Agencias", "Q"]
        ];

        data.agenciesanalysis.forEach((element) => graph3.push([element.agency, element.totalNetSales]));

        setGraph3(graph3);

        setGraph4([
          ["Enganches Captados", "Q"],
          ["Enganches Captados", companyanalysis.receivedDownPayments],
          ["Por Percibir", companyanalysis.agreedDownPayments - companyanalysis.receivedDownPayments]
        ]);

        setGraph5([
          ["Element", "Totales", { role: "style" }],
          ["", 0, '#ffffff'],
          ["Enganche Pactado a la fecha", companyanalysis.agreedDownPaymentsToDate, '#219731'],
          ["Enganche Percibido a la fecha", companyanalysis.receivedDownPayments, '#191268'],
          ["", 0, '#ffffff']
        ]);

        setGraph6([
          ["Totales", "Totales", { role: "style" }],
          ["", 0, '#ffffff'],
          ["Devengadas", companyanalysis.commissionAccrued, '#cf7c15'],
          ["Pagadas", companyanalysis.commissionPaid, '#219731'],
          ["Devengada por Pagar", companyanalysis.commissionAccrued - companyanalysis.commissionPaid, '#191268'],
          ["", 0, '#ffffff']
        ]);

        setGraph7([
          ["Ventas Netas", "Q"],
          ["Enganches Captados", companyanalysis.receivedDownPayments],
          ["Liquidaciones", companyanalysis.settlementAmount],
          ["Por Percibir", companyanalysis.totalNetSales - companyanalysis.receivedDownPayments - companyanalysis.settlementAmount]
        ]);

        historyTracker.addPath(history.location.pathname, 'Enganches Análisis',2);
        let authdata = await dataProvider.auth('Auth', { id: 'ReportDownPaymentsAnalysis', data: { action: 'read' } });
        if (authdata.data.length > 0) {
          setAuth(authdata.data[0]);
          setOpenAuth(!authdata.data[0]);
        } else {
          setAuth(false);
          setOpenAuth(true)
        }
      }
      catch {
      }
    })();
  }, [dataProvider, history.location.pathname]);

  let handleAgencyChange = (e) => {
    let agencycode = parseInt(e.target.value);
    setAgency(agencycode);

    let agencydata = agencycode === -1 ? dataCompany : dataAgency.find(x => x.agencyCode === agencycode);
    setDataAgencyActual(agencydata);

    let advisors = advisorsinfo.filter(x => x.agencyCode === agencycode);
    advisors.unshift({ value: "  ", key: "-1" })
    setAdvisors(advisors);

    if (agencycode === -1) {
      setGraph7Enable(true);
      setGraph1Title("Ventas Proyectadas / Ventas Netas");
      setGraph2Title("Venta Proyectada / Venta Percibida");
      setGraph3Title("Ventas por Agencias");
      setGraph4Title("Enganches Captados / Enganches por Percibir");
      setGraph5Title("Enganches Pactados / Enganches Percibidos");
      setGraph6Title("Comisiones");
      setGraph7Title("Ventas Netas / Ventas Percibidas");

      setGraph1([
        ["Ventas Proyectadas / Ventas", "Q"],
        ["Ventas Netas", dataCompany.totalNetSales],
        ["Por Vender", dataCompany.totalProjectedSales - dataCompany.totalNetSales]
      ]);

      setGraph2([
        ["Ventas Proyectadas", "Q"],
        ["Ventas Percibidas", dataCompany.receivedDownPayments + dataCompany.settlementAmount],
        ["Ventas por Percibidas", dataCompany.totalProjectedSales - (dataCompany.receivedDownPayments + dataCompany.settlementAmount)]
      ]);

      var graph3 = [
        ["Ventas Netas por Agencias", "Q"]
      ];

      dataAgency.forEach((element) => graph3.push([element.agency, element.totalNetSales]));
      setGraph3(graph3);

      setGraph4([
        ["Enganches Captados", "Q"],
        ["Enganches Captados", dataCompany.receivedDownPayments],
        ["Por Percibir", dataCompany.agreedDownPayments - dataCompany.receivedDownPayments]
      ]);

    } else {

      setGraph1Title("Ventas Agencia / Ventas Netas");
      setGraph2Title("Ventas por Asesor");
      setGraph3Title("Enganches Captados / Enganches por Percibir");
      setGraph4Title("Ventas Netas / Ventas Percibidas");
      setGraph7Enable(false);

      setGraph1([
        ["Ventas Proyectadas", "Q"],
        ["Ventas Agencia", agencydata.totalNetSales],
        ["Ventas Otras Agencias", dataCompany.totalNetSales - agencydata.totalNetSales]
      ]);

      var graph2 = [
        ["Ventas Netas por Agencias", "Q"]
      ];

      dataAdvisor.forEach((element) => {
        if (element.agencyCode === agencycode)
          graph2.push([element.advisor, element.totalNetSales])
      });

      setGraph2(graph2);

      setGraph3([
        ["Enganches Pactados", "Q"],
        ["Enganches Captado ", agencydata.receivedDownPayments],
        ["Por Percibir", agencydata.agreedDownPayments - agencydata.receivedDownPayments]
      ]);

      setGraph4([
        ["Ventas Netas", "Q"],
        ["Enganches Captados", agencydata.receivedDownPayments],
        ["Liquidaciones", agencydata.settlementAmount],
        ["Por Percibir", agencydata.totalNetSales - agencydata.receivedDownPayments - agencydata.settlementAmount]
      ]);
    }

    setGraph5([
      ["Element", "Totales", { role: "style" }],
      ["", 0, '#ffffff'],
      ["Enganche Pactado a la fecha", agencydata.agreedDownPaymentsToDate, '#219731'],
      ["Enganche Percibido a la fecha", agencydata.receivedDownPayments, '#191268'],
      ["", 0, '#ffffff']
    ]);

    setGraph6([
      ["Totales", "Totales", { role: "style" }],
      ["", 0, '#ffffff'],
      ["Devengadas", agencydata.commissionAccrued, '#cf7c15'],
      ["Pagadas", agencydata.commissionPaid, '#219731'],
      ["Devengada por Pagar", agencydata.commissionAccrued - agencydata.commissionPaid, '#191268'],
      ["", 0, '#ffffff']
    ]);
  }

  let handleAdvisorChange = (e) => {
    let advisorcode = parseInt(e.target.value);
    setAdvisor(advisorcode);
    let advisordata = advisorcode === -1 && agency === -1 ? dataCompany
      : advisorcode === -1 ? dataAgencyActual : dataAdvisor.find(x => x.advisorCode === advisorcode);

    if (advisorcode === -1) {
      setGraph2Title("Ventas por Agencia");
      setGraph1([
        ["Ventas Proyectadas", "Q"],
        ["Ventas Agencia", dataAgencyActual.totalNetSales],
        ["Ventas Otras Agencias", dataCompany.totalNetSales - dataAgencyActual.totalNetSales]
      ]);
    } else {
      setGraph1Title("Ventas Asesor / Ventas Netas");
      setGraph2Title("Ventas Asesor / Agencia");

      setGraph1([
        ["Ventas Proyectadas", "Q"],
        ["Ventas Asesor", advisordata.totalNetSales],
        ["Ventas Otros Asesores", dataCompany.totalNetSales - advisordata.totalNetSales]
      ]);
    }

    var graph2 = [
      ["Ventas Netas por Asesor", "Q"]
    ];

    if (advisorcode === -1) {
      dataAgency.forEach((element) => graph2.push([element.agency, element.totalNetSales]));

    } else {

      graph2.push(["Ventas Asesor", advisordata.totalNetSales]);
      graph2.push(["Ventas Otros Asesores", dataAgencyActual.totalNetSales - advisordata.totalNetSales]);
    }

    setGraph2(graph2);

    setGraph3([
      ["Enganches Captados", "Q"],
      ["Enganches Captados", advisordata.receivedDownPayments],
      ["Por Devengar", advisordata.agreedDownPayments - advisordata.receivedDownPayments]
    ]);

    setGraph4([
      ["Ventas Netas", "Q"],
      ["Enganches Captados", advisordata.receivedDownPayments],
      ["Liquidaciones", advisordata.settlementAmount],
      ["Por Percibir", advisordata.totalNetSales - advisordata.receivedDownPayments - advisordata.settlementAmount]
    ]);

    setGraph5([
      ["Element", "Totales", { role: "style" }],
      ["", 0, '#ffffff'],
      ["Enganche Pactado a la fecha", advisordata.agreedDownPaymentsToDate, '#219731'],
      ["Enganche Percibido a la fecha", advisordata.receivedDownPayments, '#191268'],
      ["", 0, '#ffffff'],
    ]);

    setGraph6([
      ["Totales", "Totales", { role: "style" }],
      ["", 0, '#ffffff'],
      ["Devengadas", advisordata.commissionAccrued, '#cf7c15'],
      ["Pagadas", advisordata.commissionPaid, '#219731'],
      ["Devengada por Pagar", advisordata.commissionAccrued - advisordata.commissionPaid, '#191268'],
      ["", 0, '#ffffff']
    ]);
  }
  const PostFilter = () => {
    return (
      <div class="row" style={{ marginBottom: '1em' }}>
        <SelectCustom onChange={handleAgencyChange} select={agency} label={"Agencias"} options={agencies} />
        <SelectCustom onChange={handleAdvisorChange} select={advisor} label={"Asesores"} options={advisors} />
      </div>
    )
  };

  const handleRedirectFail = () => {
    history.push(`/reports/downpayment/fail`);
  };

  const handleRedirectPortfolio = () => {
    history.push(`/reports/downpayment/portfolio`);
  };

  const handleRedirectPayments = () => {
    history.push(`/reports/downpayment/payments`);
  };

  const handleRedirectAnalysis = () => {
    history.push(`/reports/downpayment/analysis`);
  };

  const [openDownPayments, setOpeDownPayments] = useState(true);

  const handleClickOpenDownPayments = () => {
    setOpeDownPayments(!openDownPayments);
  };

  const menus = [{
    icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
    open: openDownPayments,
    openAction: handleClickOpenDownPayments,
    actions: [
      { icon: <img src={fail} alt="logo" style={Style.MenuLogo} />, name: 'Rescindidos/Rechazados', onClick: handleRedirectFail },
      { icon: <img src={book} alt="logo" style={Style.MenuLogo} />, name: 'Saldos Cartera', onClick: handleRedirectPortfolio },
      { icon: <img src={pay} alt="logo" style={Style.MenuLogo} />, name: 'Pagos', onClick: handleRedirectPayments },
      { icon: <img src={stats} alt="logo" style={Style.MenuLogo} />, name: 'Análisis Completo', onClick: handleRedirectAnalysis },
    ]
  }]

  return (
    <div class="container">
      <BreadCrumbCustom history={historyTracker.getHistory()} />
      {auth ? (
        <div class="row">
          <div class="col-12">
            {isSmall ? (
              null
            ) : (
              <div class="container">
                <PostFilter />
                <div class="row">
                  <div class="col-6" style={Style.ReportTitle}>
                    {graph1Title}
                  </div>
                  <div class="col-6" style={Style.ReportTitle}>
                    {graph2Title}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <Chart
                      width={'100%'}
                      height={300}
                      chartType="PieChart"
                      loader={<div style={{ fontFamily: 'Gothic' }}>Cargando ...</div>}
                      data={graph1}
                      options={{
                        titlePosition: 'none',
                        chartArea: { width: '100%' },
                        fontName: 'Gothic',
                        is3D: true,
                        colors: ['#219731', '#191268']
                      }}
                      legendToggle
                    />
                  </div>
                  <div class="col-6">
                    <Chart
                      width={'100%'}
                      height={300}
                      chartType="PieChart"
                      loader={<div style={{ fontFamily: 'Gothic' }}>Cargando ...</div>}
                      data={graph2}
                      options={{
                        titlePosition: 'none',
                        chartArea: { width: '100%' },
                        fontName: 'Gothic',
                        is3D: true
                      }}
                      legendToggle
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-6" style={Style.ReportTitle}>
                    {graph3Title}
                  </div>
                  <div class="col-6" style={Style.ReportTitle}>
                    {graph4Title}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <Chart
                      width={'100%'}
                      height={300}
                      chartType="PieChart"
                      loader={<div style={{ fontFamily: 'Gothic' }}>Cargando ...</div>}
                      data={graph3}
                      options={{
                        titlePosition: 'none',
                        chartArea: { width: '100%' },
                        fontName: 'Gothic',
                        pieHole: 0.4,
                        is3D: false
                      }}
                      legendToggle
                    />
                  </div>
                  <div class="col-6">
                    <Chart
                      width={'100%'}
                      height={300}
                      chartType="PieChart"
                      loader={<div style={{ fontFamily: 'Gothic' }}>Cargando ...</div>}
                      data={graph4}
                      options={{
                        titlePosition: 'none',
                        chartArea: { width: '100%' },
                        fontName: 'Gothic',
                        is3D: false,
                        pieHole: 0.4,
                        colors: ['#cf7c15', '#191268', '#219731']
                      }}
                      legendToggle
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-6" style={Style.ReportTitle}>
                    {graph5Title}
                  </div>
                  <div class="col-6" style={Style.ReportTitle}>
                    {graph6Title}
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <Chart
                      width={'100%'}
                      height={300}
                      chartType="ColumnChart"
                      loader={<div style={{ fontFamily: 'Gothic' }}>Cargando ...</div>}
                      data={graph5}
                      options={{
                        titlePosition: 'none',
                        chartArea: { width: '100%' },
                        fontName: 'Gothic',
                        x: {
                          0: { side: 'top', label: 'White to move' } // Top x-axis.
                        }
                      }}
                    />
                  </div>
                  <div class="col-6">
                    <Chart
                      width={'100%'}
                      height={300}
                      chartType="ColumnChart"
                      loader={<div style={{ fontFamily: 'Gothic' }}>Cargando ...</div>}
                      data={graph6}
                      options={{
                        titlePosition: 'none',
                        chartArea: { width: '100%' },
                        fontName: 'Gothic'
                      }}
                    />
                  </div>
                </div>
                {graph7Enable ? (
                  <div class="row">
                    <div class="col-6" style={Style.ReportTitle}>
                      {graph7Title}
                    </div>
                  </div>
                ) : (null)}
                {graph7Enable ? (
                  < div class="row">
                    <div class="col-6">
                      <Chart
                        width={'100%'}
                        height={300}
                        chartType="PieChart"
                        loader={<div style={{ fontFamily: 'Gothic' }}>Cargando ...</div>}
                        data={graph7}
                        options={{
                          titlePosition: 'none',
                          chartArea: { width: '100%' },
                          fontName: 'Gothic',
                          is3D: true
                        }}
                        legendToggle
                      />
                    </div>
                  </div>) : (null)
                }

                <BottomToolBar menu={<InternalMenu menus={menus} />} />
                <br />
              </div>
            )}
          </div>
        </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)
      }
    </div >
  )
};

export default RequestList;