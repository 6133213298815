import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {Style } from '../Commons/Styles.jsx';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AuthNotification = (props) => {
    const { message, severity, openerror } = props;

    return (
        <div class="row" style={Style.Alert}>
            <Snackbar open={openerror} autoHideDuration={4000}>
                <div>
                    <Alert severity={severity} style={Style.AlertMessage}>
                        {message}
                    </Alert>
                </div>
            </Snackbar>
        </div>
    );
};

export default AuthNotification;