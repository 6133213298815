import { useNotify, useDataProvider, List, TextField, TextInput, SelectInput, NumberField } from 'react-admin';
import Datagrid from '../Commons/Datagrid';
import { useEffect, useState } from "react";
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useMediaQuery } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import BreadCrumbCustom from '../Commons/BreadCrum';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import { Style, StyleTheme } from '../Commons/Styles.jsx';
import BottomToolBar from '../Commons/BottomToolBar';
import AuthNotification from '../Commons/AuthNotification';
import SimpleDialog from '../Commons/Dialog.jsx';
import PostPagination from '../Commons/PostPagination';
import { createStyles, makeStyles } from '@material-ui/core';
import FilterSidebar from '../Commons/FilterSidebar';
import ListActions from '../Commons/ListActions';
import States from '../Commons/States';
import Validate from '../Commons/Validate';
import credits from '../Commons/Icons/creditos.svg';
import settle from '../Commons/Icons/settle.svg';
import general from '../Commons/Icons/gears.svg';
import InternalMenu from '../Commons/InternalMenu';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PostCreate = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const useStyles = makeStyles(() => createStyles(StyleTheme));
    const classes = useStyles();
    const [agency, setAgency] = useState(null);
    const [agencies, setAgencies] = useState([]);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState(null);
    const [advisors, setAdvisors] = useState([]);
    const [advisor, setAdvisor] = useState(null);
    const [collectors, setCollectors] = useState([]);
    const [collector, setCollector] = useState(null);
    const [campaigns, setCampaigns] = useState([]);
    const [campaign, setCampaign] = useState(null);
    const [channels, setChannels] = useState([]);
    const [channel, setChannel] = useState(null);
    const [promotions, setPromotions] = useState([]);
    const [promotion, setPromotion] = useState(null);
    const [realstate, setRealState] = useState(null);
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState(null);
    const [deadlines, setDeadlines] = useState([]);
    const [deadline, setDeadline] = useState(null);
    const [payments, setPayments] = useState([]);
    const [payment, setPayment] = useState(null);
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [openAuth, setOpenAuth] = useState(false);
    const [auth, setAuth] = useState(false);
    const [closeDialog, setCloseDialog] = useState(true);
    const [closeDialogRealState, setCloseDialogRealState] = useState(true);
    const [customer, setCustomer] = useState(null);
    const [amount, setAmount] = useState("0.00");
    const [reserve, setReserve] = useState("0.00");
    const [commission, setCommission] = useState("0.00");
    const [installments, setInstallments] = useState(0);
    const [date, setDate] = useState(null);
    const [firstPayment, setFirstPayment] = useState(null);
    const [types, setTypes] = useState([]);
    const [disabledRealState, setDisabledRealState] = useState(true);
    const [error, setError] = useState(false);
    const [errorDate, setErrorDate] = useState(false);
    const [errorFirstPayment, setErrorFirstPayment] = useState(false);
    const [errorRealState, setErrorRealState] = useState(false);

    const dataProvider = useDataProvider();

    const notify = useNotify();
    const history = useHistory();

    const handleClickError = () => {
        setOpenerror(true);
    };

    useEffect(() => {
        (async () => {
            try {
                const authdata = await dataProvider.auth('Auth', { id: 'DownPayment', data: { action: 'create' } });
                if (authdata.data.length > 0) {
                    setAuth(authdata.data[0]);
                    setOpenAuth(!authdata.data[0]);
                } else {
                    setAuth(false);
                    setOpenAuth(true)
                    return;
                }

                if (authdata.data[0]) {
                    let data = await dataProvider.getList('agencies/downpayments/general', { filter: {}, pagination: {}, sort: {} });
                    var agencies = data.data
                        .filter(item => item.active === true)
                        .map(item => (
                            {
                                key: item.id,
                                value: item.name
                            }
                        ));

                    setAgencies(agencies);
                    if (agencies.length > 0) {
                        setAgency(agencies[0].key);
                        const [productsData, advisorsData, collectorsData] = await Promise.all([
                            dataProvider.getList('products/downpayments/general/active', { filter: { agency: agencies[0].key }, pagination: {}, sort: {} }),
                            dataProvider.getList('advisors/downpayments/general/assigned', { filter: { agency: agencies[0].key }, pagination: {}, sort: {} }),
                            dataProvider.getList('collectors/downpayments/general/active', { filter: { agency: agencies[0].key }, pagination: {}, sort: {} })
                        ]);

                        var products = productsData.data.map(item => ({ key: item.id, value: item.name }));
                        setProducts(products);
                        if (products.length > 0) {
                            setProduct(products[0].key);
                            setDisabledRealState(false);
                            States.setProductDownPayment(products[0].key);
                        }

                        var advisors = advisorsData.data.map(item => ({ key: item.id, value: item.name }));
                        setAdvisors(advisors);
                        if (advisors.length > 0)
                            setAdvisor(advisors[0].key);

                        var collectors = collectorsData.data.map(item => ({ key: item.id, value: item.name }));
                        setCollectors(collectors);
                        if (collectors.length > 0)
                            setCollector(collectors[0].key);
                    }

                    const [campaignsData, channelsData, promotionsData, currenciesData, deadlinesData, paymentsData, typesData] = await Promise.all([
                        dataProvider.getList('campaign/downpayments/general/active', { filter: {}, pagination: {}, sort: {} }),
                        dataProvider.getList('channel/downpayments/general/active', { filter: {}, pagination: {}, sort: {} }),
                        dataProvider.getList('promotion/downpayments/general/active', { filter: {}, pagination: {}, sort: {} }),
                        dataProvider.getList('currency', { filter: {}, pagination: {}, sort: {} }),
                        dataProvider.getList('downpayment/deadline', { filter: {}, pagination: {}, sort: {} }),
                        dataProvider.getList('downpayment/payments', { filter: {}, pagination: {}, sort: {} }),
                        dataProvider.getList('realstatetype/', { filter: {}, pagination: {}, sort: {} })
                    ]);

                    var campaigns = campaignsData.data.map(item => ({ key: item.id, value: item.name }));
                    setCampaigns(campaigns);
                    if (campaigns.length > 0)
                        setCampaign(campaigns[0].key);

                    var channels = channelsData.data.map(item => ({ key: item.id, value: item.name }));
                    setChannels(channels);
                    if (channels.length > 0)
                        setChannel(channels[0].key);

                    var promotions = promotionsData.data.map(item => ({ key: item.id, value: item.name }));
                    setPromotions(promotions);
                    if (promotions.length > 0)
                        setPromotion(promotions[0].key);

                    var currencies = currenciesData.data.map(item => ({ key: item.id, value: item.name }));
                    setCurrencies(currencies);
                    if (currencies.length > 0)
                        setCurrency(currencies[0].key);

                    var deadlines = deadlinesData.data.map(item => ({ key: item.id, value: item.name }));
                    setDeadlines(deadlines);
                    if (deadlines.length > 0)
                        setDeadline(deadlines[0].key);

                    var payments = paymentsData.data.map(item => ({ key: item.id, value: item.name }));
                    setPayments(payments);
                    if (payments.length > 0)
                        setPayment(payments[0].key);

                    var types = typesData.data.map(item => ({ id: item.id, name: item.description }));
                    setTypes(types);
                }
            }
            catch {
            }
        })();
    }, [dataProvider]);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    const PostDownPayment = async () => {
        let control = 0;
        if (!Validate(amount, { money: true, required: true }))
            control++;

        if (!Validate(reserve, { money: true, required: true }))
            control++;

        if (!Validate(commission, { money: true, required: true }))
            control++;

        if (!Validate(customer?.name, { required: true })) {
            setError(true);
            control++;
        }

        if (!Validate(realstate?.realState, { required: true })) {
            setErrorRealState(true);
            control++;
        }

        if (!Validate(date, { required: true })) {
            setErrorDate(true);
            control++;
        }

        if (!Validate(firstPayment, { required: true })) {
            setErrorFirstPayment(true);
            control++;
        }

        if (control > 0) {
            setMessage("No se pudo crear el Enganche")
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            customer: customer?.id.toString(),
            realState: realstate?.id.toString(),
            product: product.toString(),
            agency: agency?.toString(),
            advisor: advisor?.toString(),
            collector: collector?.toString(),
            campaign: campaign?.toString(),
            channel: channel?.toString(),
            promotion: promotion?.toString(),
            currency: currency?.toString(),
            deadline: deadline?.toString(),
            payment: payment?.toString(),
            installments: installments.toString(),
            amount: amount.replace(/[^0-9.-]/g, ''),
            reserve: reserve.replace(/[^0-9.-]/g, ''),
            commission: commission.replace(/[^0-9.-]/g, ''),
            date,
            firstPayment
        }

        dataProvider.create('downpayment', { data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                history.push('/downpayment/general/' + response.data.id + '/show');
                notify('Enganche creado correctamente')
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo crear el Enganche. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const onChangeAgency = async (props) => {
        try {
            const [productsData, advisorsData, collectorsData] = await Promise.all([
                dataProvider.getList('products/downpayments/general/active', { filter: { agency: props.target.value }, pagination: {}, sort: {} }),
                dataProvider.getList('advisors/downpayments/general/assigned', { filter: { agency: props.target.value }, pagination: {}, sort: {} }),
                dataProvider.getList('collectors/downpayments/general/active', { filter: { agency: props.target.value }, pagination: {}, sort: {} })
            ]);

            var products = productsData.data.map(item => ({ key: item.id, value: item.name }));
            setProducts(products);
            if (products.length > 0) {
                setProduct(products[0].key);
                setDisabledRealState(false);
                States.setProductDownPayment(products[0].key);
            } else {
                setDisabledRealState(true);
            }

            var advisors = advisorsData.data.map(item => ({ key: item.id, value: item.name }));
            setAdvisors(advisors);
            if (advisors.length > 0)
                setAdvisor(advisors[0].key);

            var collectors = collectorsData.data.map(item => ({ key: item.id, value: item.name }));
            setCollectors(collectors);
            if (collectors.length > 0)
                setCollector(collectors[0].key);

        } catch (error) {

        }
    }

    const onChangeProduct = async (props) => {
        States.setProductDownPayment(props.target.value);
    }

    const onClick = async (e) => {
        setCloseDialog(true);
        let record = await dataProvider.getOne('customer/downpayment', { id: e });
        setCustomer(record.data);
        setError(false);
    }

    const onClickRealState = async (e) => {
        setCloseDialogRealState(true);
        let record = await dataProvider.getOne('realstate', { id: e });
        setRealState(record.data);
        setErrorRealState(false);
    }

    const Customers = () => {
        const PostFilter = [
            <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Cliente"}</span>} source="customer" alwaysOn />,
            <TextInput InputProps={{ disableUnderline: true }} label={<span>{"NIT"}</span>} source="nit" alwaysOn />,
            <TextInput InputProps={{ disableUnderline: true }} label={<span>{"CUI"}</span>} source="cui" alwaysOn />,
        ];
        let props = { basePath: '/customer/downpayment', resource: 'customer/downpayment', hasCreate: false, hasEdit: false, hasList: true, hasShow: false };
        return (
            <List perPage={5} {...props} aside={<FilterSidebar {...props} filters={PostFilter} />} actions={<ListActions />} bulkActionButtons={false} pagination={<PostPagination noPerPage={true} />}>
                <Datagrid rowClick={onClick}>
                    <TextField source="name" label="Cliente" sortable={false} style={Style.fieldList} cellClassName={classes.fields} />
                    <TextField source="nit" label="NIT" sortable={false} style={Style.fieldList} cellClassName={classes.fields} />
                    <TextField source="cui" sortable={false} label="CUI" style={Style.fieldList} cellClassName={classes.fields} />
                </Datagrid>
            </List>
        );
    }

    const RealStates = () => {
        const PostFilter = [
            <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Inmueble"}</span>} source="realState" alwaysOn />,
            <SelectInput InputProps={{ disableUnderline: true }} label={<span >{"Tipo"}</span>} source="type" choices={types} alwaysOn />,
        ];

        let props = { basePath: '/realstate/downpayments/product', resource: 'realstate/downpayments/product', hasCreate: false, hasEdit: false, hasList: true, hasShow: false };

        return (
            <List perPage={5} {...props} aside={<FilterSidebar {...props} filters={PostFilter} />} actions={<ListActions />} bulkActionButtons={false} pagination={<PostPagination noPerPage={true} />}>
                <Datagrid rowClick={onClickRealState}>
                    <TextField source="realState" style={Style.fieldList} label="Inmueble" sortable={false} cellClassName={classes.fields} />
                    <TextField source="typeRealState" style={Style.fieldList} label="Tipo" sortable={false} cellClassName={classes.fields} />
                    <NumberField source="price" style={Style.fieldList} label="Precio" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                </Datagrid>
            </List>
        );
    }

    let search = <SimpleDialog title="Clientes" body={<Customers />} maxWidth='md' close={closeDialog} setClose={setCloseDialog} />;
    let searchRealStae = <SimpleDialog title="Inmuebles" body={<RealStates />} maxWidth='md' close={closeDialogRealState} setClose={setCloseDialogRealState} disabled={disabledRealState} />;

    const handleRedirectSettle = () => {
        history.push(`/downpayment/settlement`);
    };

    const handleRedirectGeneral = () => {
        history.push(`/downpayment/general`);
    };

    const [openDownPayments, setOpeDownPayments] = useState(true);

    const handleClickOpenDownPayments = () => {
        setOpeDownPayments(!openDownPayments);
    };

    const menus = [{
        icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
        open: openDownPayments,
        openAction: handleClickOpenDownPayments,
        actions: [
            { icon: <img src={general} alt="logo" style={Style.MenuLogo} />, name: 'General', onClick: handleRedirectGeneral },
            { icon: <img src={settle} alt="logo" style={Style.MenuLogo} />, name: 'Liquidaciones', onClick: handleRedirectSettle },
        ]
    }]

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {auth ? (
                <div class="edit">
                    {!isSmall ? (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>) : (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>
                    )}
                    {!isSmall ? (
                        <div class="container" style={{ opacity: opacity }}>
                            <div class="row">
                                <InputFieldCustom error={error} setError={setError} col="col-6" width="100%" value={customer?.name} setValue={setCustomer} placeholder="Cliente" label="Cliente*" required type="text" component="search" readOnly={true} search={search} />
                                <InputFieldCustom error={errorDate} setError={setErrorDate} value={date} setValue={setDate} col="col-6" width="95%" placeholder="Fecha Reserva" label="Fecha Reserva" required type="date" />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-6" width='95%' setValue={setAgency} value={agency} options={agencies} component="select" label="Agencias" onChange={onChangeAgency} />
                                <InputFieldCustom col="col-6" width='95%' setValue={setProduct} value={product} options={products} component="select" label="Productos" onChange={onChangeProduct} />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-6" width='95%' setValue={setAdvisor} value={advisor} options={advisors} component="select" label="Asesores" />
                                <InputFieldCustom col="col-6" width='95%' setValue={setCollector} value={collector} options={collectors} component="select" label="Cobradores" />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-6" width='95%' setValue={setCampaign} value={campaign} options={campaigns} component="select" label="Campañas" />
                                <InputFieldCustom col="col-6" width='95%' setValue={setChannel} value={channel} options={channels} component="select" label="Canales" />
                            </div>
                            <div class="row">
                                <InputFieldCustom error={errorRealState} setError={setErrorRealState} col="col-6" width="100%" value={realstate?.realState} setValue={setRealState} placeholder="Inmueble" label="Inmueble*" required type="text" component="search" readOnly={true} search={searchRealStae} />
                                <InputFieldCustom value={realstate?.price} col="col-6" width="95%" placeholder="Precio Inmueble" label="Precio Inmueble (Q) *" required type="text" money readOnly={true} />
                            </div>
                            <div class="row">
                                <InputFieldCustom error={errorFirstPayment} setError={setErrorFirstPayment} value={firstPayment} setValue={setFirstPayment} col="col-6" width="95%" placeholder="Fecha Primer Pago" label="Fecha Primer Pago" required type="date" />
                                <InputFieldCustom col="col-6" width='95%' setValue={setPayment} value={payment} options={payments} component="select" label="Pagos" />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-6" width='95%' setValue={setPromotion} value={promotion} options={promotions} component="select" label="Promociones" />
                                <InputFieldCustom col="col-6" width='95%' setValue={setCurrency} value={currency} options={currencies} component="select" label="Monedas" />
                            </div>
                            <div class="row">
                                <InputFieldCustom col="col-6" width='95%' setValue={setDeadline} value={deadline} options={deadlines} component="select" label="Plazos" />
                                <InputFieldCustom value={installments} setValue={setInstallments} col="col-6" width="95%" placeholder="Cuotas" label="Cuotas" required type="number" />
                            </div>
                            <div class="row">
                                <InputFieldCustom value={amount} setValue={setAmount} col="col-6" width="95%" placeholder="Monto Enganche" label="Enganche (Q) *" required type="text" money />
                                <InputFieldCustom value={reserve} setValue={setReserve} col="col-6" width="95%" placeholder="Reserva" label="Reserva (Q) *" required type="text" money />
                            </div>
                            <div class="row">
                                <InputFieldCustom value={commission} setValue={setCommission} col="col-6" width="95%" placeholder="Comisión Pagada" label="Comisión Pagada (Q) *" required type="text" money />
                            </div>
                            <BottomToolBar buttons={[{ auth: true, action: PostDownPayment, name: 'Crear' }]} disabled={disable} menu={<InternalMenu menus={menus} />} />
                        </div>
                    ) : (
                        <div class="container" style={{ opacity: opacity }}>

                        </div>)
                    }
                    <div class="row" style={Style.Alert}>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" style={Style.AlertMessage} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
        </div>
    )
}

export default PostCreate
