import { Layout, MenuItemLink } from 'react-admin';
import MyAppBar from './MyAppBar'
import shop from './Components/Commons/Img/Logo.png';
import shopicon from './Components/Commons/Img/icono.png';
import { useSelector } from 'react-redux';
import MySidebar from './MySidebar';
import { useAuth0 } from "@auth0/auth0-react";
import admin from './Components/Commons/Icons/admin2.svg'
import operations from './Components/Commons/Icons/settings.svg'
import logouticon from './Components/Commons/Icons/sign-out-alt.svg'
import reports from './Components/Commons/Icons/graph.svg'
import { Style } from './Components/Commons/Styles.jsx';

const menuItems = [
    { name: 'company/administration', text: 'Administración', icon: <img src={admin} alt="logo" style={Style.MenuLogo} /> },
    { name: 'company/operations', text: 'Operaciones', icon: <img src={operations} alt="logo" style={Style.MenuLogo} /> },
    { name: 'company/reports', text: 'Reportes', icon: <img src={reports} alt="logo" style={Style.MenuLogo} /> },
];

const MyMenu = ({ onMenuClick }) => {
    const { logout } = useAuth0();
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    let iconmenu = open ? shop : shopicon;
    let maxHeight = open ? '5em' : '2.5em';
    let maxWidth = open ? '12em' : '2.5em';
    let paddingLeftmax = open ? '1.4em' : '1em';

    const onClick = async () => {
        logout();
    }

    return (
        <div>
            <a href="/">
                <img src={iconmenu} alt="logo" style={{ maxWidth: maxWidth, maxHeight: maxHeight, marginTop: '1em', paddingLeft: paddingLeftmax }} />
            </a>

            {menuItems.map(item => (
                <MenuItemLink
                    dense={true}
                    key={item.name}
                    to={`/${item.name}`}
                    primaryText={item.text}
                    leftIcon={item.icon}
                    onClick={onMenuClick}
                    style={Style.MenuItem}
                />
            ))}
            <MenuItemLink
                dense={true}
                key="Cerrar Sesión"
                to={`/logout`}
                primaryText="Cerrar Sesión"
                leftIcon={<img src={logouticon} alt="logo" style={Style.MenuLogo} />}
                onClick={onClick}
                style={Style.MenuItem}
            />
        </div>)
};

const MyLayout = (props) => {
    const { module } = props;
    return (
        <Layout {...props} menu={MyMenu} appBar={props => <MyAppBar {...props} module={module} />} sidebar={MySidebar} />)
};

export default MyLayout;