
const States = {

    Company: 0,
    Companies: [],
    User: '',
    Module: '',
    DownPayment: '',
    OrganizationId: '',
    Organizations: [],
    NIT: '',
    ProductDownPayment: '',

    setOrganizations: (organizations) => {
        States.Organizations = organizations;
        localStorage.setItem('Organizations', organizations);
    },

    getOrganizations: () => {
        let local = localStorage.getItem('Organizations');
        if (States.Organizations === '' && local !== '')
            return local;
        return States.Organizations;
    },

    setOrganizationId: (organizationId) => {
        States.OrganizationId = organizationId;
        localStorage.setItem('OrganizationId', organizationId);
    },

    getOrganizationId: () => {
        let local = localStorage.getItem('Organizations');
        if (States.Organizations === '' && local !== '')
            return local;
        return States.OrganizationId;
    },

    setNIT: (NIT) => {
        States.NIT = NIT;
        localStorage.setItem('NIT', NIT);
    },

    getNIT: () => {
        let local = localStorage.getItem('NIT');
        if (States.NIT === '' && local !== '')
            return local;
        return States.NIT;
    },

    setDownPayment: (downpayment) => {
        States.DownPayment = downpayment;
        localStorage.setItem('DownPayment', downpayment);
    },

    getDownPayment: () => {
        let local = localStorage.getItem('DownPayment');
        if (States.DownPayment === '' && local !== '')
            return local;
        return States.DownPayment;
    },

    setUser: (user) => {
        States.User = user;
        localStorage.setItem('User', user);
    },

    getUser: () => {
        let local = localStorage.getItem('User');
        if (States.User === '' && local !== '')
            return local;
        return States.User;
    },

    setModule: (module) => {
        States.Module = module;
        localStorage.setItem('Module', module);
    },

    getModule: () => {
        let local = localStorage.getItem('Module');
        if (States.Module === '' && local !== '')
            return local;
        return States.Module;
    },

    setCompany: (company) => {
        States.Company = company;
        localStorage.setItem('Company', company);
    },

    getCompany: () => {
        let local = localStorage.getItem('Company');
        if (States.Company === '' && local !== '')
            return local;
        return States.Company;
    },

    setCompanies: (companies) => {
        States.Companies = companies;
        localStorage.setItem('Companies', companies);
    },

    getCompanies: () => {
        let local = localStorage.getItem('Companies');
        if (States.Companies === '' && local !== '')
            return local;
        return States.Companies;
    },

    setProductDownPayment: (productDownPayment) => {
        States.ProductDownPayment = productDownPayment;
        localStorage.setItem('ProductDownPayment', productDownPayment);
    },

    getProductDownPayment: () => {
        let local = localStorage.getItem('ProductDownPayment');
        if (States.ProductDownPayment === '' && local !== '')
            return local;
        return States.ProductDownPayment;
    },
};

export default States;