import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { http } from "./https";
import States from './Components/Commons/States';

const domain = process.env.REACT_APP_URL_API ?? "";
const domainAdmin = 'https://' + process.env.REACT_APP_AUTH0_DOMAIN ?? "";
const clientSecret = process.env.REACT_APP_AUTH0_CLIENT_SECRET_ADMIN ?? "";
const client_id = process.env.REACT_APP_AUTH0_CLIENT_ID_ADMIN ?? "";

const withValidations = (Component) => (props) => {
    const { isAuthenticated, getIdTokenClaims } = useAuth0();
    const [com, setCom] = useState(<span>Espere...</span>);

    useEffect(() => {
        (async () => {
            try {
                var claims = await getIdTokenClaims();
                if (!isAuthenticated) {
                    setCom(<div><span>Error no estas autenticado</span></div>);
                    return;
                }

                if (!claims?.email_verified) {
                    setCom(<div className="centered"><div className="child"><span>Antes de continuar verifica tu email.</span></div></div>);
                    return;
                }

                try {
                    http(new Request(`${domain}/HandShake`, {
                        method: 'GET',
                    }));

                    let data = {
                        grant_type: "client_credentials",
                        client_id: client_id,
                        client_secret: clientSecret,
                        audience: `${domainAdmin}/api/v2/`
                    }

                    var formBody = [];
                    for (var property in data) {
                        var encodedKey = encodeURIComponent(property);
                        var encodedValue = encodeURIComponent(data[property]);
                        formBody.push(encodedKey + "=" + encodedValue);
                    }

                    formBody = formBody.join("&");

                    const admin = await http(new Request(`${domainAdmin}/oauth/token`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: formBody
                    }));

                    const adminAccess = await http(new Request(`${domainAdmin}/api/v2/users/${claims.sub}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + admin.access_token,
                        }
                    }));

                    let userMeta = adminAccess.user_metadata;

                    const queryParams = { filter: JSON.stringify({ id: userMeta.organizations }) };
                    console.log(queryParams);
                    const queryString = new URLSearchParams(queryParams).toString();
                    const organizationsdata = await http(new Request(`${domain}/organizations/identifiers?${queryString}`, {
                        method: 'GET'
                    }));

                    var organizations = organizationsdata.map(item => (
                        {
                            key: item.id,
                            value: item.name
                        }
                    ));

                    States.setOrganizations(organizations);
                    States.setOrganizationId(organizations[0].key);

                    const companiesdata = await http(new Request(`${domain}/company/user`, {
                        method: 'GET',
                        headers: {
                            user: claims?.sub,
                            username: claims?.nickname,
                            organization: organizations[0].key
                        }
                    }));

                    var companies = companiesdata.map(item => (
                        {
                            key: item.id,
                            value: item.name
                        }
                    ));

                    States.setCompanies(companies);

                }
                catch {
                    setCom(<div className="centered"><div className="child"><span>Estamos pasando por problemas, intente en un momento.</span></div></div>);
                    return;
                }

                setCom(<Component {...props} />);
            } catch (e) {
                console.log(e);
            }
        })();
    }, [isAuthenticated, getIdTokenClaims, props]);
    return com;
};

export default withValidations