import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Style } from '../Commons/Styles.jsx';

const BreadCrumbCustom = (props) => {
    const { history } = props;
    return (
        <Breadcrumbs>
            {
                history.links.map((item, index) => (
                    <Link
                        underline="hover"
                        color="inherit"
                        href={'/#' + item}
                    >
                        <Typography variant="body1" style={Style.BreadCrum}>
                            {history.names[index]}
                        </Typography>
                    </Link>
                ))
            }
        </Breadcrumbs>
    );
};

export default BreadCrumbCustom;