import { List, NumberField, TextField, TextInput, SelectInput, useDataProvider, DateInput, DateField } from 'react-admin';
import Datagrid from '../Commons/Datagrid';
import PostPagination from '../Commons/PostPagination';
import ListActions from '../Commons/ListActions';
import { useMediaQuery, createTheme } from '@material-ui/core';
import BreadCrumbCustom from '../Commons/BreadCrum';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import historyTracker from '../Commons/historyTracker';
import AuthNotification from '../Commons/AuthNotification';
import show from './DownPaymentsPaymentShow';
import FilterSidebar from '../Commons/FilterSidebar';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import InternalMenu from '../Commons/InternalMenu';
import credits from '../Commons/Icons/creditos.svg';
import fail from '../Commons/Icons/faild.svg';
import pay from '../Commons/Icons/pay.svg';
import book from '../Commons/Icons/book.svg';
import stats from '../Commons/Icons/stats.svg';

const RequestList = (props) => {
  const useStyles = makeStyles(() => createStyles(StyleTheme));
  const classes = useStyles();
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const [advisors, setAdvisors] = useState([]);
  const [states, setStates] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [products, setProducts] = useState([]);
  const [openAuth, setOpenAuth] = useState(false);
  const [auth, setAuth] = useState(false);
  const [defaultvalues, setdefaultvalues] = useState({});
  const dataProvider = useDataProvider();

  useEffect(() => {
    (async () => {
      try {
        let data = await dataProvider.getMany('advisors/downpayments/general', { ids: [] });
        var advisors = data.data.map(item => (
          {
            id: item.id,
            name: item.name
          }
        ));

        setAdvisors(advisors);

        data = await dataProvider.getMany('agencies/downpayments/general', { ids: [] });
        var agencies = data.data.map(item => (
          {
            id: item.id,
            name: item.name
          }
        ));

        setAgencies(agencies);

        var states = [
          {
            id: 2,
            name: 'Revertido'
          },
          {
            id: 1,
            name: 'Activo'
          }
        ];

        setStates(states);

        data = await dataProvider.getMany('products/downpayments/', { ids: [] });
        var products = data.data.map(item => (
          {
            id: item.id,
            name: item.name
          }
        ));

        setProducts(products);

        historyTracker.addPath(history.location.pathname, 'Enganches Pagados', 2);

        const authdata = await dataProvider.auth('Auth', { id: 'ReportDownPaymentsPayment', data: { action: 'read' } });
        if (authdata.data.length > 0) {
          setAuth(authdata.data[0]);
          setOpenAuth(!authdata.data[0]);
        } else {
          setAuth(false);
          setOpenAuth(true)
        }

        setdefaultvalues({ dateStart: new Date(), dateEnd: new Date() });
      }
      catch {
      }
    })();
  }, [dataProvider, history.location.pathname]);

  const PostFilter = [
    <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Imueble"}</span>} source="realState" alwaysOn />,
    <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Clientes"}</span>} source="customer" alwaysOn />,
    <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Operación"}</span>} source="operation" alwaysOn />,
    <TextInput InputProps={{ disableUnderline: true }} label={<span>{"Recibo"}</span>} source="receiptNumber" alwaysOn />,
    <DateInput InputProps={{ disableUnderline: true }} label={<span>{"Inicio"}</span>} source="dateStart" alwaysOn />,
    <DateInput InputProps={{ disableUnderline: true }} label={<span>{"Fin"}</span>} source="dateEnd" alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{"Estado"}</span>} source="state" choices={states} alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{"Asesor"}</span>} source="advisor" choices={advisors} alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{"Agencia"}</span>} source="agency" choices={agencies} alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span>{"Producto"}</span>} source="product" choices={products} alwaysOn />
  ]

  const handleRedirectFail = () => {
    history.push(`/reports/downpayment/fail`);
  };

  const handleRedirectPortfolio = () => {
    history.push(`/reports/downpayment/portfolio`);
  };

  const handleRedirectPayments = () => {
    history.push(`/reports/downpayment/payments`);
  };

  const handleRedirectAnalysis = () => {
    history.push(`/reports/downpayment/analysis`);
  };

  const [openDownPayments, setOpeDownPayments] = useState(true);

  const handleClickOpenDownPayments = () => {
    setOpeDownPayments(!openDownPayments);
  };

  const menus = [{
    icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
    open: openDownPayments,
    openAction: handleClickOpenDownPayments,
    actions: [
      { icon: <img src={fail} alt="logo" style={Style.MenuLogo} />, name: 'Rescindidos/Rechazados', onClick: handleRedirectFail },
      { icon: <img src={book} alt="logo" style={Style.MenuLogo} />, name: 'Saldos Cartera', onClick: handleRedirectPortfolio },
      { icon: <img src={pay} alt="logo" style={Style.MenuLogo} />, name: 'Pagos', onClick: handleRedirectPayments },
      { icon: <img src={stats} alt="logo" style={Style.MenuLogo} />, name: 'Análisis Completo', onClick: handleRedirectAnalysis },
    ]
  }]

  let pagination = <PostPagination menu={<InternalMenu menus={menus} />} />;

  return (
    <div class="container">
      <BreadCrumbCustom history={historyTracker.getHistory()} />
      {auth ? (
        <div class="row">
          <div class="col-12">
            {isSmall ? (
              <List empty={false} {...props} actions={<ListActions />} bulkActionButtons={false} pagination={pagination}>
                <Datagrid {...props} expand={show} rowClick="expand">
                </Datagrid>
              </List>
            ) : (
              <List empty={false} perPage={10} {...props} aside={<FilterSidebar {...props} filters={PostFilter} defaultvalues={defaultvalues} />} actions={<ListActions download />} bulkActionButtons={false} pagination={pagination}>
                <Datagrid {...props} expand={show} rowClick="expand" style={Style.DataGrid}>
                  <TextField source="realState" style={Style.fieldList} label="Inmuebles" sortable={false} cellClassName={classes.fields} />
                  <TextField source="customer" style={Style.fieldList} label="Cliente" sortable={false} cellClassName={classes.fields} />
                  <TextField source="operation" style={Style.fieldList} label="Operación" sortable={false} cellClassName={classes.fields} />
                  <DateField source="datePaymentInfo" style={Style.fieldList} label="Pago" showTime={false} sortable={false} cellClassName={classes.fields} />
                  <DateField source="dateApplicationInfo" style={Style.fieldList} label="Aplicación" showTime={false} sortable={false} cellClassName={classes.fields} />
                  <TextField source="state" style={Style.fieldList} label="Estado" sortable={false} cellClassName={classes.fields} />
                  <NumberField source="amountPayment" style={Style.fieldList} label="Monto Pagado" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                </Datagrid>
              </List>
            )}
          </div>
        </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
    </div>
  )
};

export default RequestList;