import { useNotify, useDataProvider } from 'react-admin';
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useState, useEffect } from "react";
import { useMediaQuery } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import BreadCrumbCustom from '../Commons/BreadCrum';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import { Style } from '../Commons/Styles.jsx';
import BottomToolBar from '../Commons/BottomToolBar';
import building from '../Commons/Icons/house-building.svg';
import credits from '../Commons/Icons/creditos.svg';
import house from '../Commons/Icons/home.svg';
import InternalMenu from '../Commons/InternalMenu';
import AuthNotification from '../Commons/AuthNotification';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PostCreate = (props) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [description, setDescription] = useState('');
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [openAuth, setOpenAuth] = useState(false);
    const [auth, setAuth] = useState(false);
    const dataProvider = useDataProvider();

    const notify = useNotify();
    const history = useHistory();

    const handleClickError = () => {
        setOpenerror(true);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    useEffect(() => {
        (async () => {
            try {
                const authdata = await dataProvider.auth('Auth', { id: 'RealStateType', data: { action: 'create' } });
                if (authdata.data.length > 0) {
                    setAuth(authdata.data[0]);
                    setOpenAuth(!authdata.data[0]);
                } else {
                    setAuth(false);
                    setOpenAuth(true)
                    return;
                }
            }
            catch {
            }
        })();
    }, [dataProvider]);

    const PostRealStateType = async () => {
        let control = 0;

        if (!Validate(description, true, { required: true }))
            control++;


        if (control > 0) {
            setMessage("No se pudo crear el Tipo de Inmueble")
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            description,
        }

        dataProvider.create('realstatetype', { data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                history.push('/realstatetype/' + response.data.id + '/show');
                notify('Tipo de Inmueble creado correctamente')
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo crear el Tipo de Inmueble. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const handleRedirectRealState = () => {
        history.push(`/realstate`);
    };

    const handleRedirectRealStateType = () => {
        history.push(`/realstatetype`);
    };

    const [openDownPayments, setOpeDownPayments] = useState(true);

    const handleClickOpenDownPayments = () => {
        setOpeDownPayments(!openDownPayments);
    };

    const menus = [{
        icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
        open: openDownPayments,
        openAction: handleClickOpenDownPayments,
        actions: [
            { icon: <img src={house} alt="logo" style={Style.MenuLogo} />, name: 'Inmuebles', onClick: handleRedirectRealState },
            { icon: <img src={building} alt="logo" style={Style.MenuLogo} />, name: 'Tipo Inmuebles', onClick: handleRedirectRealStateType },
        ]
    }];


    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {auth ? (
                <div class="edit">
                    {!isSmall ? (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>) : (
                        <div class="Loading" style={{ display: displayLoading }}>
                            <CircularProgress size="15rem" />
                        </div>
                    )}
                    {!isSmall ? (
                        <div class="container" style={{ opacity: opacity }}>
                            <div class="row">
                                <InputFieldCustom value={description} setValue={setDescription} col="col-6" width="95%" placeholder="Ingresa Tipo de Inmueble" label="Nombre" type="text" />
                            </div>
                            <BottomToolBar buttons={[{ auth: true, action: PostRealStateType, name: 'Crear' }]} disabled={disable} menu={<InternalMenu menus={menus} />} />
                        </div>
                    ) : (
                        <div class="container" style={{ opacity: opacity }}>

                        </div>)
                    }
                    <div class="row" style={Style.Alert}>
                        <div>
                            <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                                <div>
                                    <Alert onClose={handleCloseError} severity="error" style={Style.AlertMessage} >
                                        {message}
                                    </Alert>
                                </div>
                            </Snackbar>
                        </div>
                    </div>
                </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
        </div>
    )
}

export default PostCreate
