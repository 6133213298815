import { List, NumberField, TextField, BooleanField, TextInput, SelectInput, useDataProvider } from 'react-admin';
import Datagrid from '../Commons/Datagrid';
import PostPagination from '../Commons/PostPagination';
import ListActions from '../Commons/ListActions';
import { useMediaQuery, createTheme } from '@material-ui/core';
import BreadCrumbCustom from '../Commons/BreadCrum';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import historyTracker from '../Commons/historyTracker';
import AuthNotification from '../Commons/AuthNotification';
import { StyleTheme, Style } from '../Commons/Styles.jsx';
import { createStyles, makeStyles } from '@material-ui/core';
import FilterSidebar from '../Commons/FilterSidebar';
import building from '../Commons/Icons/house-building.svg';
import credits from '../Commons/Icons/creditos.svg';
import house from '../Commons/Icons/home.svg';
import InternalMenu from '../Commons/InternalMenu';
import { useHistory } from 'react-router-dom';

const RequestList = (props) => {
  const useStyles = makeStyles(() => createStyles(StyleTheme));
  const classes = useStyles();
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [Types, setTypes] = useState([]);
  const [openAuth, setOpenAuth] = useState(false);
  const [auth, setAuth] = useState(false);
  const [authCreate, setAuthCreate] = useState(false);
  const dataProvider = useDataProvider();
  const location = useLocation();
  const history = useHistory();

  const States = [{
    id: 'A',
    name: 'Activo'
  },
  {
    id: 'I',
    name: 'Inactivo'
  }];

  const Disponibilities = [{
    id: 'Disponible',
    name: 'Disponible'
  },
  {
    id: 'Comprometido',
    name: 'Comprometido'
  }]

  useEffect(() => {
    (async () => {
      try {
        const { data } = await dataProvider.getMany('realstatetype/', { ids: [] });
        const authdata = await dataProvider.auth('Auth', { id: 'RealState', data: { action: 'list,create' } });
        var types = data.map(item => (
          {
            id: item.id,
            name: item.description
          }
        ));

        setTypes(types);

        historyTracker.addPath(location.pathname, 'Inmuebles',2);

        if (authdata.data.length > 0) {
          setAuth(authdata.data[0]);
          setOpenAuth(!authdata.data[0]);
          setAuthCreate(authdata.data[1]);
        } else {
          setAuth(false);
          setOpenAuth(true)
        }
      }
      catch {
      }
    })();
  }, [dataProvider, location.pathname]);

  const PostFilter = [
    <TextInput InputProps={{ disableUnderline: true }} label={<span >{"Imueble"}</span>} source="realState" alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span >{"Tipo"}</span>} source="type" choices={Types} alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span >{"Estado"}</span>} source="state" choices={States} alwaysOn />,
    <SelectInput InputProps={{ disableUnderline: true }} label={<span >{"Disponibilidad"}</span>} source="disponibility" choices={Disponibilities} alwaysOn />
  ];

  const handleRedirectRealState = () => {
    history.push(`/realstate`);
  };

  const handleRedirectRealStateType = () => {
    history.push(`/realstatetype`);
  };

  const [openDownPayments, setOpeDownPayments] = useState(true);

  const handleClickOpenDownPayments = () => {
    setOpeDownPayments(!openDownPayments);
  };

  const menus = [{
    icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
    open: openDownPayments,
    openAction: handleClickOpenDownPayments,
    actions: [
      { icon: <img src={house} alt="logo" style={Style.MenuLogo} />, name: 'Inmuebles', onClick: handleRedirectRealState },
      { icon: <img src={building} alt="logo" style={Style.MenuLogo} />, name: 'Tipo Inmuebles', onClick: handleRedirectRealStateType },
    ]
  }];

  let pagination = <PostPagination menu={<InternalMenu menus={menus} />} />;

  return (
    <div class="container">
      <BreadCrumbCustom history={historyTracker.getHistory()} />
      {auth ? (
        <div class="row">
          <div class="col-12">
            {isSmall ? (
              <List empty={false} {...props} filters={<PostFilter />} actions={<ListActions create={authCreate} />} bulkActionButtons={false} pagination={pagination}>
                <Datagrid {...props} rowClick="show">
                </Datagrid>
              </List>
            ) : (
              <List empty={false} {...props} aside={<FilterSidebar {...props} filters={PostFilter} />} actions={<ListActions create={authCreate} download />} bulkActionButtons={false} pagination={pagination}>
                <Datagrid {...props} rowClick="show" style={Style.DataGrid}>
                  <TextField source="realState" style={Style.fieldList} label="Inmueble" sortable={false} cellClassName={classes.fields} />
                  <TextField source="state" style={Style.fieldList} label="Estado" sortable={false} cellClassName={classes.fields} />
                  <NumberField source="price" style={Style.fieldList} label="Precio" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <NumberField source="amount" style={Style.fieldList} label="Monto NF" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <NumberField source="cost" style={Style.fieldList} label="Costo" sortable={true} cellClassName={classes.fields} options={{ style: 'currency', currency: 'GTQ' }} />
                  <TextField source="realStateState" style={Style.fieldList} label="Tipo" sortable={false} cellClassName={classes.fields} />
                  <TextField source="disponibility" style={Style.fieldList} label="Disponibilidad" sortable={false} cellClassName={classes.fields} />
                  <BooleanField source="exonerationIVA" style={{ color: 'black', fontFamily: 'Gothic', marginLeft: '7em' }} label="Exoneración IVA" sortable={false} cellClassName={classes.fields} />
                </Datagrid>
              </List>
            )}
          </div>
        </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
    </div>
  )
};

export default RequestList;