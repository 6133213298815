import { useNotify, useDataProvider } from 'react-admin';
import { useEffect, useState } from "react";
import InputFieldCustom from '../Commons/InputFieldCustom'
import { useMediaQuery } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Validate from '../Commons/Validate';
import CircularProgress from '@material-ui/core/CircularProgress';
import BreadCrumbCustom from '../Commons/BreadCrum';
import historyTracker from '../Commons/historyTracker';
import { useHistory } from 'react-router-dom';
import States from '../Commons/States';
import { Style } from '../Commons/Styles.jsx';
import BottomToolBar from '../Commons/BottomToolBar';
import credits from '../Commons/Icons/creditos.svg';
import settle from '../Commons/Icons/settle.svg';
import general from '../Commons/Icons/gears.svg';
import InternalMenu from '../Commons/InternalMenu';
import AuthNotification from '../Commons/AuthNotification';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PostCreate = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [comment, setComment] = useState('');
    const [amount, setAmount] = useState('');
    const [date, setDate] = useState('');
    const [openerror, setOpenerror] = useState(false);
    const [message, setMessage] = useState('');
    const [opacity, setOpacity] = useState(1);
    const [displayLoading, setDisplayLoading] = useState('none');
    const [disable, setDisable] = useState(false);
    const [typeTransaction, setTypeTransaction] = useState(null);
    const [typeTransactions, setTypeTransactions] = useState([]);
    const [openAuth, setOpenAuth] = useState(false);
    const [auth, setAuth] = useState(false);
    const dataProvider = useDataProvider();

    const notify = useNotify();
    const history = useHistory();

    const handleClickError = () => {
        setOpenerror(true);
    };

    useEffect(() => {
        (async () => {
            try {
                const authdata = await dataProvider.auth('Auth', { id: 'SettlementDownPayment', data: { action: 'create' } });
                if (authdata.data.length > 0) {
                    setAuth(authdata.data[0]);
                    setOpenAuth(!authdata.data[0]);
                } else {
                    setAuth(false);
                    setOpenAuth(true)
                    return;
                }

                const { data } = await dataProvider.getMany('deductions/', { ids: [] });
                var types = data.map(item => (
                    {
                        key: item.id,
                        value: item.description
                    }
                ));

                setTypeTransactions(types);
                setTypeTransaction(types[0].key);
                let timestamp = Date.parse(data[0].date);
                let date = new Date(timestamp);
                let month = date.getMonth() + 1;
                let day = date.getDate();
                setDate(date.getFullYear() + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day))
            }
            catch {
            }
        })();
    }, [dataProvider]);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenerror(false);
    };

    function PostSettlementDownPayment() {
        let control = 0;

        if (!Validate(amount, { money: true, required: true }))
            control++;


        if (!Validate(comment, { required: true }))
            control++;

        if (!Validate(date, { required: true }))
            control++;

        if (control > 0) {
            setMessage("No se pudo crear la Liquidación")
            handleClickError();
            return;
        }

        setOpacity(0.3);
        setDisable(true);
        setDisplayLoading('block');

        var info = {
            typeTransaction,
            amount,
            comment,
            date,
            downPayment: States.getDownPayment()
        }

        dataProvider.create('settlement/downpayment', { data: info })
            .then(response => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                history.push('/settlement/downpayment/' + response.data.id + '/show');
                notify('Liquidación creada correctamente')
            })
            .catch((error) => {
                setOpacity(1);
                setDisable(false);
                setDisplayLoading('none');
                setMessage("No se pudo actualizar la Liquidación. " + error.toString().replace('HttpError: ', ''));
                handleClickError()
            })
    }

    const handleRedirectSettle = () => {
        history.push(`/downpayment/settlement`);
    };

    const handleRedirectGeneral = () => {
        history.push(`/downpayment/general`);
    };

    const [openDownPayments, setOpeDownPayments] = useState(true);

    const handleClickOpenDownPayments = () => {
        setOpeDownPayments(!openDownPayments);
    };

    const menus = [{
        icon: <img src={credits} alt="logo" style={Style.MenuLogo} />, name: 'Enganches',
        open: openDownPayments,
        openAction: handleClickOpenDownPayments,
        actions: [
            { icon: <img src={general} alt="logo" style={Style.MenuLogo} />, name: 'General', onClick: handleRedirectGeneral },
            { icon: <img src={settle} alt="logo" style={Style.MenuLogo} />, name: 'Liquidaciones', onClick: handleRedirectSettle },
        ]
    }]

    return (
        <div class="container">
            <BreadCrumbCustom history={historyTracker.getHistory()} />
            {auth ? (
            <div class="edit">
                {!isSmall ? (
                    <div class="Loading" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>) : (
                    <div class="Loading" style={{ display: displayLoading }}>
                        <CircularProgress size="15rem" />
                    </div>
                )}
                {!isSmall ? (
                    <div class="container" style={{ opacity: opacity }}>
                        <div class="row">
                            <InputFieldCustom value={date} setValue={setDate} col="col-4" width='100%' type="date" label="Fecha" />
                            <InputFieldCustom value={typeTransaction} setValue={setTypeTransaction} options={typeTransactions} col="col-4" width='100%' type="text" label="Tipo Transacción" component="select" />
                            <InputFieldCustom value={amount} setValue={setAmount} col="col-4" placeholder="Ingrese un Monto" width='100%' type="text" label="Monto *" money />
                        </div>
                        <div class="row">
                            <InputFieldCustom value={comment} setValue={setComment} col="col-4" width="100%" placeholder="Ingrese Comentario" label="Commentario *" required component="textarea" />
                        </div>
                        <BottomToolBar buttons={[{ auth: true, action: PostSettlementDownPayment, name: 'Crear' }]} disabled={disable} menu={<InternalMenu menus={menus} />} />
                    </div>
                ) : (
                    <div class="container">
                    </div>)
                }
                <div class="row" style={Style.Alert}>
                    <div>
                        <Snackbar open={openerror} autoHideDuration={4000} onClose={handleCloseError}>
                            <div>
                                <Alert onClose={handleCloseError} severity="error" style={Style.AlertMessage} >
                                    {message}
                                </Alert>
                            </div>
                        </Snackbar>
                    </div>
                </div>
            </div>) : (<AuthNotification openerror={openAuth} severity={'error'} message={'No posee los permisos para poder interactuar con esta vista'} />)}
        </div>
    )
}

export default PostCreate
